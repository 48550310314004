import React, { FC, ReactElement, useEffect } from 'react';
import { Stack, Dropdown, IDropdownOption } from '@fluentui/react';
import * as ApiType from '../../../../../../solutionCenterApi/gen';
import { useIntl } from 'react-intl';
import { dropdownStyles, stackTokens } from './dropdownControl.styles';
import CustomRenderLabel from './customLabelRenderer';
import * as Type from '../../../../../../common/Type';
import Utils from '../../../../../../utils';
import styles from '../../armStyling/index.styles';
import MessageBarControl from './messageBarControl';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../../../redux';
import { messageConfigType } from '../../../../../../common/Type';
import * as Enum from '../../../../../../common/Enum';
import { nonRequiredField } from '../instanceComponentBuilder';

export interface DropdownControlBuilderProps {
  azureOfferRowKey?: string;
  parameterElements: ApiType.ParameterDefinition;

  parameterDefinitionsCallback: (parameterDefinition: ApiType.ParameterDefinition) => void;
  dropdownValidationCallback: (dropdown: Type.ParameterValidation) => void;
  permissionAuthorizationFieldIncluded: Type.permissionAuthorizationFieldIncluded;
}

export const DropdownControlBuilder: FC<DropdownControlBuilderProps> = (
  props: DropdownControlBuilderProps
): ReactElement => {
  const { parameterElements, permissionAuthorizationFieldIncluded } = props;
  const intlShape = useIntl();
  const [parameterOptionSelected, setParameterOptionSelected] = React.useState(
    props.parameterElements?.value ? props.parameterElements?.value : props.parameterElements?.defaultValue
  );
  const hasPermissionAuthorization: Enum.PermissionState = useSelector(
    (state: RootStore) => state.azureInstance.hasPermissionAuthorization
  );

  useEffect(() => {
    if (parameterElements.type === ApiType.ParameterType.Bool) {
      parameterElements.allowedValues = [true, false];
    }
    if (Utils.isNullOrUndefinedOrEmpty(parameterOptionSelected) && !nonRequiredField(props.parameterElements)) {
      props.dropdownValidationCallback({
        name: parameterElements.name,
        isValidationFailed: true,
      });
    } else {
      props.dropdownValidationCallback({
        name: parameterElements.name,
        isValidationFailed: false,
      });
    }

    permissionAuthorizationFieldIncluded ?? setParameterOptionsForRbac();
  }, [parameterElements, hasPermissionAuthorization]);

  const parameterOptions = parameterElements.allowedValues?.map((val: string, index: number) => ({
    index: index,
    key: val,
    text: String(val),
  }));

  const setParameterOptionsForRbac = () => {
    parameterElements.value = hasPermissionAuthorization === Enum.PermissionState.GRANTED ? true : false;
    props.parameterDefinitionsCallback(parameterElements);
    props.dropdownValidationCallback({
      name: parameterElements.name,
      isValidationFailed: false,
    });
  };

  const onSelectionChange = (option: IDropdownOption) => {
    const newSelectedValue = parameterOptions?.filter((each) => option.key === each.key)[0];
    let selectedValue = newSelectedValue?.key;
    setParameterOptionSelected(selectedValue);
    parameterElements.value = selectedValue;
    props.parameterDefinitionsCallback(parameterElements);
    props.dropdownValidationCallback({
      name: parameterElements.name,
      isValidationFailed: false,
    });
  };

  const onRenderLabel = (): JSX.Element => {
    return CustomRenderLabel(
      intlShape,
      'destination.instanceName',
      Utils.getTitleCase(parameterElements.name),
      'tooltip.instance.description',
      parameterElements.description ?? '',
      'destination.accessibility.armInfoIcon',
      nonRequiredField(parameterElements)
    );
  };

  const onGetErrorMessage = (selectedValue: string): boolean => {
    if (
      selectedValue === undefined ||
      selectedValue === null ||
      !parameterElements.allowedValues?.includes(selectedValue)
    ) {
      return true;
    }
    return false;
  };
  const messagePrimaryConfigurationList = (): messageConfigType[] => {
    let mesageConfigList: messageConfigType[] = [];
    mesageConfigList.push({
      name: 'arm.PermissionAuthNeededBullet1',
      tagType: 'bold',
    });
    mesageConfigList.push({
      name: 'arm.PermissionAuthNeededBullet2',
      tagType: 'link',
    });
    mesageConfigList.push({
      name: 'arm.PermissionAuthNeededBullet3',
      tagType: 'bold',
    });
    return mesageConfigList;
  };

  const messageSecondaryConfigurationList = (): messageConfigType[] => {
    let mesageConfigList: messageConfigType[] = [];
    mesageConfigList.push({
      name: 'arm.additionalConfigNeeded.bullet.1',
      tagType: 'bold',
    });
    mesageConfigList.push({
      name: 'arm.additionalConfigNeeded.bullet.2',
      tagType: 'bold',
    });
    return mesageConfigList;
  };

  return (
    <div className={styles.verticalSpacing}>
      <Stack tokens={stackTokens}>
        {!permissionAuthorizationFieldIncluded.isHidden && (
          <Dropdown
            options={parameterOptions}
            selectedKey={parameterOptionSelected}
            onChanged={onSelectionChange}
            styles={dropdownStyles}
            errorMessage={
              onGetErrorMessage(parameterOptionSelected)
                ? intlShape.formatMessage({
                    id: 'arm.dropdown.validation.message',
                  })
                : ''
            }
            role="none"
            onRenderLabel={onRenderLabel}
            data-test-id="dropdown-control-builder-wrapper"
            data-test-values={`dropdown-control-builder-wrapper-${parameterElements.name}-${parameterOptionSelected}`}
          />
        )}
        {permissionAuthorizationFieldIncluded.fieldName &&
          hasPermissionAuthorization === Enum.PermissionState[Enum.PermissionState.GRANTED] && (
            <MessageBarControl
              messageHeader="arm.PermissionAuthNeeded1"
              messagePrimaryDescription="arm.PermissionAuthNeeded2"
              messagePrimaryConfigurationList={messagePrimaryConfigurationList()}
              helperLink="arm.learnmore"
              messageBarType="info"
            />
          )}
        {permissionAuthorizationFieldIncluded.fieldName &&
          hasPermissionAuthorization === Enum.PermissionState[Enum.PermissionState.DENIED] && (
            <MessageBarControl
              messageHeader="arm.additionalConfigNeeded.header.1"
              messagePrimaryDescription="arm.additionalConfigNeeded.message.1"
              messagePrimaryConfigurationList={messagePrimaryConfigurationList()}
              messageSecondaryDescription="arm.additionalConfigNeeded.message.2"
              messageSecondaryConfigurationList={messageSecondaryConfigurationList()}
              ancillaryMessage="arm.additionalConfigNeeded.ancillary"
              helperLink="arm.learnmore"
              messageBarType="warning"
            />
          )}
      </Stack>
    </div>
  );
};
