import { PackageType } from '../common/Enum';
import * as Type from '../common/Type';
import { DeploymentPayloadV2 } from '../solutionCenterApi/gen';
import {
  validateARMDeploymentParams,
  validateCIDeploymentParams,
  validateDataverseDeploymentParams,
} from '../validators/validateDeploymentParams';

export class PackageValidator {
  private packageType: PackageType;

  constructor(packageType: PackageType) {
    this.packageType = packageType;
  }
}

export class ARMPackageValidator extends PackageValidator {
  public validation(armPayload: Type.AzureDeployableInstance | null): Type.Result<Type.AzureDeployableInstance> {
    return validateARMDeploymentParams(armPayload);
  }
}

export class CIPackageValidator extends PackageValidator {
  public validation(deploymentPayload: DeploymentPayloadV2): Type.Result<DeploymentPayloadV2> {
    return validateCIDeploymentParams(deploymentPayload);
  }
}

export class DataversePackageValidator extends PackageValidator {
  public validation(deploymentPayload: DeploymentPayloadV2): Type.Result<DeploymentPayloadV2> {
    return validateDataverseDeploymentParams(deploymentPayload);
  }
}
