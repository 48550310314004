import { ISeparatorStyles, mergeStyleSets, IStackStyles, IStackTokens } from '@fluentui/react';
import ZOOMED_MODE from '../../utils/root';

const styles = mergeStyleSets({
  mainContainer: {
    height: '234px',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    background: 'linear-gradient(270.72deg, #053385 0%, #0B53CE 43.8%, #1F6CF9 95.19%)',
    overflow: 'hidden',
    '@media(max-width: 960px)': {
      height: '150px',
    },
    '@media(max-width: 480px)': {
      height: '290px',
    },
  },
  username: {
    fontSize: '28px',
    lineHeight: '32px',
    display: 'inline-block',
    verticalAlign: 'text-bottom',
    left: 'calc(50% - 285px/2 - 277.5px)',
    marginBottom: 10,
    fontWeight: 600,
    '@media(max-width: 960px)': {
      fontWeight: ZOOMED_MODE.zoomFontWeight,
      lineHeight: '28px',
    },
  },
  usernameSpan: {
    '@media(max-width: 960px)': {
      display: 'flex',
    },
  },
  bannerDiv: {
    marginTop: '45px',
    display: 'inline-block',
    marginLeft: '32px',
    '@media(max-width: 960px)': {
      marginTop: '10px',
    },
  },
  bannerContent: {
    margin: '0 24px',
    float: 'left',
  },
  headerLeftContainer: {
    left: 'calc(50% - 285px/2 - 277.5px)',
    width: 'auto',
    color: '#FFFFFF',
    '@media(max-width: 480px)': {
      width: '225px',
    },
  },
  headerLeftContainerLabel: {
    height: '36px',
    color: '#FFFFFF',
    '@media(max-width: 960px)': {
      fontSize: ZOOMED_MODE.zoomFontSize,
    },
    '@media(max-width: 480px)': {
      paddingRight: 20,
    },
  },
  headerRightContainer: {
    float: 'right',
    marginTop: '20px',
    marginRight: '107.35px',
  },
  cardButtonContainer: {
    height: '182px',
    width: '320px',
    margin: '32px',
    shadowBox: 'rgba(0, 0, 0, 0.1)',
  },
  solutionContainer: {
    marginTop: '16px',
    paddingLeft: '16px',
    paddingBottom: '19px',
    display: 'inline-block',
    '@media(max-width: 960px)': {
      display: 'flex',
    },
  },
  homePageContentContainer: {
    backgroundColor: '#FAF9F8',
    minHeight: '100%',
    paddingLeft: '16px',
    '@media(max-width: 480px)': {
      paddingLeft: '7px',
    },
  },
  seperatorStyle: {
    width: '1092px',
  },
  showMoreLink: {
    paddingLeft: '16px',
  },
  solutionCardContainer: {
    marginRight: '20px',
  },
  getStartedDescription: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    float: 'left',
    margin: '-13px',
    paddingLeft: '13px',
    '@media(max-width: 380px)': {
      height: '102px',
    },
  },
  getStarted: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  getStartedContainer: {
    paddingTop: '42px',
    paddingLeft: '16px',
  },
});

export const separatorStyle: Partial<ISeparatorStyles> = {
  root: {
    ':before': {
      marginLeft: '20px',
    },
  },
};
export const stackStyles: IStackStyles = {
  root: {
    width: '100%',
    marginTop: '34px',
    marginBottom: '34px',
    marginLeft: '3px',
    maxWidth: '1208px',
    '@media(max-width: 480px)': {
      maxWidth: '100%',
      width: '270px',
    },
  },
};

export const sectionStackTokens: IStackTokens = { childrenGap: 20 };
export default styles;
