import {
  CDS_ENVIRONMENT,
  DeploymentDispatchTypes,
  SELECTED_CAPABILITY_INDEX,
  SELECTED_CAPABILITY_KEY,
  SHOW_ALL_L03S,
  REGION_BY_ORCHESTRATOR_TYPE,
  DEPLOYMENT_DEFINITION,
  CI_REGION_PAYLOAD,
  SELECTED_OPTIONAL_COMPONENT,
} from './deploymentTypes';
import * as Type from '../../common/Type';
import * as ApiType from '../../solutionCenterApi/gen/index';

const getSelectedCdsEnvironment = (
  selectedCdsEnvironment: Type.Environment | ApiType.Instance | null
): DeploymentDispatchTypes => ({
  type: CDS_ENVIRONMENT,
  payload: selectedCdsEnvironment,
});

export function getSelectedCapabilityIndex(selectedCapabilityIndex: number): DeploymentDispatchTypes {
  return {
    type: SELECTED_CAPABILITY_INDEX,
    payload: selectedCapabilityIndex,
  };
}

export function getSelectedCapabilityKey(selectedCapabilityKey: string): DeploymentDispatchTypes {
  return {
    type: SELECTED_CAPABILITY_KEY,
    payload: selectedCapabilityKey,
  };
}

export function getShowAllL03s(showAllL03s: boolean): DeploymentDispatchTypes {
  return {
    type: SHOW_ALL_L03S,
    payload: showAllL03s,
  };
}

export function getDeploymentDefinition(
  deploymentDefinition: ApiType.DeploymentDefinitionEntity | null
): DeploymentDispatchTypes {
  return {
    type: DEPLOYMENT_DEFINITION,
    payload: deploymentDefinition,
  };
}

export function setCIRegionPayload(ciRegionPayload: ApiType.CIRegionPayload | null): DeploymentDispatchTypes {
  return {
    type: CI_REGION_PAYLOAD,
    payload: ciRegionPayload,
  };
}

export function setRegionsByOrchestratorType(
  regionsByOrchestratorType: Array<Type.RegionsByOrchestratorType>
): DeploymentDispatchTypes {
  return {
    type: REGION_BY_ORCHESTRATOR_TYPE,
    payload: regionsByOrchestratorType,
  };
}

export function setSelectedOptionalComponents(
  selectedOptionalComponents: Array<ApiType.OptionalComponent>
): DeploymentDispatchTypes {
  return {
    type: SELECTED_OPTIONAL_COMPONENT,
    payload: selectedOptionalComponents,
  };
}

export default getSelectedCdsEnvironment;
