import {
  SELECTED_AZURE_OFFERS,
  AzureInstanceDispatchTypes,
  PARAMETER_DEFINITION_PROVIDER,
  SUBSCRIPTION,
  RESOURCE_GROUP,
  LOCATION,
  ERROR_VALIDATION_CONTROL_TYPE,
  ERROR_VALIDATION_CHECK_SUBSCRIPTION_TYPE,
  ERROR_VALIDATION_CHECK_RESOURCEGROUP_TYPE,
  HASPERMISSIONAUTHORIZATION,
  ARM_DEPLOYMENT_SCOPE_PROVIDER,
} from './azureInstanceTypes';
import * as Type from '../../common/Type';
import * as ApiType from '../../solutionCenterApi/gen/index';
import {
  IAzureResourceGroup,
  IAzureSubscription,
  IAzureLocation,
} from '../../pages/install/destination/azureResourceWidget/models/azureResource.Models';
import { PermissionState } from '../../common/Enum';
import { ArmDeploymentScope } from '../../solutionCenterApi/gen/index';

export const getSelectedAzureOffers = (selectedAzureOffers: ApiType.L03[]): AzureInstanceDispatchTypes => ({
  type: SELECTED_AZURE_OFFERS,
  payload: selectedAzureOffers,
});

export const setArmDeploymentScopeProvider = (offerToDeploymentScope: {
  [key in keyof typeof ArmDeploymentScope]?: string[];
}): AzureInstanceDispatchTypes => ({
  type: ARM_DEPLOYMENT_SCOPE_PROVIDER,
  payload: offerToDeploymentScope,
});

export const setParameterDefinitionProvider = (
  parameterDefinitionProvider: Type.ParameterDefinitionDictionary[]
): AzureInstanceDispatchTypes => ({
  type: PARAMETER_DEFINITION_PROVIDER,
  payload: parameterDefinitionProvider,
});

export const setErrorValidationControlType = (errorValidationControlType: boolean): AzureInstanceDispatchTypes => ({
  type: ERROR_VALIDATION_CONTROL_TYPE,
  payload: errorValidationControlType,
});

export const setErrorValidationCheckSubscriptionType = (
  errorValidationCheckSubscriptionType: boolean
): AzureInstanceDispatchTypes => ({
  type: ERROR_VALIDATION_CHECK_SUBSCRIPTION_TYPE,
  payload: errorValidationCheckSubscriptionType,
});

export const setErrorValidationCheckResourceGroupType = (
  errorValidationCheckResourceGroupType: boolean
): AzureInstanceDispatchTypes => ({
  type: ERROR_VALIDATION_CHECK_RESOURCEGROUP_TYPE,
  payload: errorValidationCheckResourceGroupType,
});

export const setSubscription = (subscription: IAzureSubscription | null): AzureInstanceDispatchTypes => ({
  type: SUBSCRIPTION,
  payload: subscription,
});

export const setResourceGroup = (resourceGroup: IAzureResourceGroup): AzureInstanceDispatchTypes => ({
  type: RESOURCE_GROUP,
  payload: resourceGroup,
});

export const setLocation = (location: IAzureLocation | null): AzureInstanceDispatchTypes => ({
  type: LOCATION,
  payload: location,
});

export const setHasPermissionAuthorization = (
  hasPermissionAuthorization: PermissionState
): AzureInstanceDispatchTypes => ({
  type: HASPERMISSIONAUTHORIZATION,
  payload: hasPermissionAuthorization,
});
