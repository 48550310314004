import * as React from 'react';
import { ActionButton, IconButton } from '@fluentui/react';
import { PreDeploymentConfigurations } from '../preDeploymentConfigurations/preDeploymentConfigurations';
import LoadingScreen from '../../../common/loading-screen';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import styles, { responsiveLicenseStatusBar, responsiveStatusBar, topMargin } from './solutionsContainer.styles';
import * as Type from '../../../common/Type';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import DeployService from '../../../services/deployService';
import InstallationStateContainer from '../../../stateContainers/installationStateContainer';
import { UpdateDialog } from '../update-dialog/update-dialog';
import { telemetry } from '../../../services/telemetryService';
import { telemetryConstants } from '../../../config';
import DependencyService from '../../../services/dependencyService';
import { UpdateDetails } from './update-details/update-details';
import * as Enum from '../../../common/Enum';
import { DeploymentFailedStatus } from '../../../components/statusBars/deploymentFailedStatus';
import { InProgressStatus } from '../../../components/statusBars/inProgressStatus';
import { LicenseMissingStatus } from '../../../components/statusBars/licenseMissingStatus';
import { UpdateAvailableStatus } from '../../../components/statusBars/updateAvailableStatus';
import LearnMoreDialog from '../../products/product/learn-more-dialog/index';
import Utils, { getUniqDependencies } from '../../../utils';
import EnvironmentService from '../../../services/environmentsService';
import Visible from '../../../components/visible/visible';
import { AZURE, TelemetryPropertyNames } from '../../../common/Constants';
import { ResponsiveModeContext } from '../../../contexts/ResponsiveModeContext';
import { getEndUserLaunchLink, isMultiPackagePresent } from '../../../utils/deployHelper';
import EnvironmentTable from '../../install/success/solutions-table/environmentTable';
import successStyles from '../../install/success/success.styles';
import RuntimeConfigStateContainer from '../../../stateContainers/runtimeConfigStateContainer';
import { validateApplicationPackages } from '../../../utils/applicationPackagesValidationHelper';

type solutionsContainerProps = {
  solution: ApiType.L03;
  deployStatus: string;
  l01RowKey: string;
  getDeploymentL03s: () => void;
} & WrappedComponentProps;

type deploymentDetailsState = {
  downArrowClicked: boolean;
  updateButtonClicked: boolean;
  isUpdateDialogOpen: boolean;
  updateConfirmed: boolean;
  termsChecked: boolean;
  licenseMissingClicked: boolean;
  preDeploymentDependencies: ApiType.L03Dependency[];
  disableUpdateButton: boolean;
  isFetchingDependencies: boolean;
  apiUrl: string;
  instanceName: string;
};

class SolutionsContainer extends React.Component<solutionsContainerProps, deploymentDetailsState> {
  runtimeConfig = RuntimeConfigStateContainer.getConfiguration();
  constructor(props: solutionsContainerProps) {
    super(props);

    this.state = {
      downArrowClicked: false,
      updateButtonClicked: false,
      isUpdateDialogOpen: false,
      updateConfirmed: false,
      termsChecked: false,
      licenseMissingClicked: false,
      preDeploymentDependencies: [],
      disableUpdateButton: false,
      isFetchingDependencies: true,
      apiUrl: props.solution.instanceApiUrl,
      instanceName: '',
    };
  }

  static contextType = ResponsiveModeContext;

  componentDidMount = async () => {
    await this.getCurrentEnvironment();
    await this.checkDependencies();
    await this.validatePackages();
  };

  logLinkClick = (name: string) => {
    var buttonSelected: any = {};
    buttonSelected[telemetryConstants.deploymentDetails.DEPLOYMENT_DETAILS_TELEMETRY_KEY] = name;
    telemetry.logEvents(telemetryConstants.deploymentDetails.DEPLOYMENT_DETAILS_TELEMETRY_NAME, buttonSelected);
  };

  externalLinkClickHandler = (name: string, url: string) => {
    this.logLinkClick(name);
    window.open(url, '_blank');
  };

  onDownButtonClick = () => {
    this.setState({
      downArrowClicked: true,
    });
  };

  onUpButtonClick = () => {
    this.setState({
      downArrowClicked: false,
    });
  };

  onUpdateButtonClick = () => {
    this.setState({
      updateButtonClicked: true,
      isUpdateDialogOpen: true,
    });
    this.logLinkClick(telemetryConstants.events.UPDATE_BUTTON_CLICKED);
  };

  onDialogDismiss = () => {
    this.setState({
      updateButtonClicked: false,
      isUpdateDialogOpen: false,
      updateConfirmed: false,
      termsChecked: false,
    });
  };

  onConfirmClick = async () => {
    try {
      const deploymentL03V2UpdatePayload: ApiType.DeploymentL03V2UpdatePayload = {
        deploymentL03V2RowKey: this.props.solution.deploymentL03V2RowKey,
        l01RowKey: this.props.l01RowKey,
      };

      const envs = await EnvironmentService.getEnvironments(this.props.l01RowKey);
      const existingEnv: ApiType.Instance[] = envs.filter(
        (env: ApiType.Instance) => env.id === this.props.solution.instanceId
      );
      if (existingEnv.length !== 0) {
        deploymentL03V2UpdatePayload.environmentId = existingEnv[0].environmentId;
      }

      var result = await DeployService.updateL03(deploymentL03V2UpdatePayload);
      InstallationStateContainer.setDeploymentId(result?.deploymentId);

      telemetry.logTrace(
        'User kicked off an update for ' + this.props.solution.name,
        telemetryConstants.severity.SEVERITY_INFO
      );

      await this.props.getDeploymentL03s();

      this.setState({
        updateConfirmed: true,
        disableUpdateButton: true,
      });

      this.logLinkClick(telemetryConstants.events.UPDATE_CONFIRMED);
    } catch (error) {
      telemetry.logException(error);
    }
  };

  onTermsChecked = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    this.setState({
      termsChecked: !!checked,
    });
    this.logLinkClick(telemetryConstants.events.UPDATE_TERMS_ACCEPTED);
  };

  onLicenseMissingClicked = () => {
    this.setState({
      licenseMissingClicked: true,
    });
  };

  closeLearnMoreDialog = () => {
    this.setState({
      licenseMissingClicked: false,
    });
  };
  returnUserLaunchName = (): string => {
    return 'success.openSolution';
  };

  validatePackages = async () => {
    if (this.props.solution?.isUpdateAvailable === true) {
      let isPackageValid = await validateApplicationPackages(this.props.l01RowKey, this.props.solution);

      this.setState({
        disableUpdateButton: this.state.disableUpdateButton || !isPackageValid,
      });
    }
  };

  getCurrentEnvironment = async () => {
    const envs = await EnvironmentService.getEnvironments(this.props.l01RowKey);
    const existingEnv: ApiType.Instance[] = envs.filter(
      (env: Type.Environment) => env.id === this.props.solution.instanceId
    );
    if (existingEnv.length !== 0) {
      this.setState({ apiUrl: existingEnv[0].apiUrl });
      this.setState({ instanceName: existingEnv[0].friendlyName });
    }
  };

  checkDependencies = async () => {
    var customProps: any = {};
    try {
      customProps[TelemetryPropertyNames.instanceIdKey] = this.props.solution.instanceId;
      const preDependencies: ApiType.L03Dependency[] | undefined = this.props.solution.l03Dependencies?.filter(
        (x) => x.preOrPostDeploy === 'Pre'
      );
      const uniquePreDependencies: ApiType.L03Dependency[] = getUniqDependencies(preDependencies);
      if (this.props.solution?.isUpdateAvailable === true && uniquePreDependencies.length !== 0) {
        if (!Utils.isNullOrUndefinedOrEmpty(this.state.instanceName)) {
          customProps[TelemetryPropertyNames.instanceNameKey] = this.state.instanceName;
          customProps[TelemetryPropertyNames.instanceApiUrlKey] = this.state.apiUrl;
          telemetry.logTrace('checking dependencies for current env', telemetryConstants.severity.SEVERITY_INFO);

          const dependencies: ApiType.DependencyCheckResult = await DependencyService.getPreDeploymentDependencies(
            this.state.apiUrl,
            this.props.solution.instanceId,
            uniquePreDependencies
          );

          if (dependencies !== undefined) {
            customProps[TelemetryPropertyNames.dependenciesKey] = dependencies;
            telemetry.logTrace('Pre-deployment dependencies checked', telemetryConstants.severity.SEVERITY_INFO);

            const checkedDependencies: (ApiType.L03Dependency & ApiType.DependencyCheckFetchXMLResult)[] =
              getUniqDependencies(preDependencies).reduce((acc, current: ApiType.L03Dependency) => {
                const checked = dependencies.dependencyCheckFetchXMLResult?.find(
                  (value: ApiType.DependencyCheckFetchXMLResult) => value.l03DependencyId === current.rowKey
                );
                if (!checked) {
                  return acc.concat({
                    ...current,
                    isConfigured: true,
                    isDeployed: true,
                  });
                }
                return acc.concat({
                  ...current,
                  isDeployed: checked.isDeployed,
                  isConfigured: checked.isConfigured,
                });
              }, []);
            const checkIsConfigured = checkedDependencies.find(
              (dependency: ApiType.DependencyCheckFetchXMLResult) => dependency.isConfigured === false
            );
            const checkIsDeployed = checkedDependencies.find(
              (dependency: ApiType.DependencyCheckFetchXMLResult) => dependency.isDeployed === false
            );

            const isLicensePresent = Utils.productHasLicense(this.props.solution);

            telemetry.logTrace(
              !isLicensePresent
                ? 'The user does not have the necessary licenses to update'
                : 'The user has the necessary licenses to update',
              telemetryConstants.severity.SEVERITY_INFO
            );

            telemetry.logTrace(
              checkIsDeployed || checkIsDeployed !== undefined
                ? 'The user does not have the dependencies deployed'
                : 'The user has the dependencies deployed',
              telemetryConstants.severity.SEVERITY_INFO
            );

            telemetry.logTrace(
              checkIsConfigured || checkIsConfigured !== undefined
                ? 'The user does not have the dependencies configured'
                : 'TThe user has the dependencies configured',
              telemetryConstants.severity.SEVERITY_INFO
            );

            this.setState({
              preDeploymentDependencies: checkedDependencies,
              disableUpdateButton:
                checkIsDeployed !== undefined || checkIsConfigured !== undefined || !isLicensePresent ? true : false,
              isFetchingDependencies: false,
            });
          } else {
            telemetry.logEvents(telemetryConstants.events.DEPENDENCY_CHECK_FAILED, customProps);

            this.setState({
              disableUpdateButton: true,
              isFetchingDependencies: false,
            });
          }
        } else {
          telemetry.logTrace(
            'Unable to get environment Id for dependency check',
            telemetryConstants.severity.SEVERITY_INFO
          );

          this.setState({
            disableUpdateButton: true,
            isFetchingDependencies: false,
          });
        }
      } else {
        this.setState({
          preDeploymentDependencies: uniquePreDependencies,
          isFetchingDependencies: false,
        });
      }
    } catch (error) {
      telemetry.logException(error);
    }
  };

  renderDeploymentStatus = (
    isLicensePresent: boolean,
    isMultiPackagePresent: boolean,
    status: string,
    updateAvailable: boolean | undefined
  ) => {
    if (status === Enum.DeploymentStatus[Enum.DeploymentStatus.Success]) {
      let licenseStatus = Enum.DeploymentStatus.Completed;
      if (updateAvailable) {
        telemetry.logTrace(this.props.solution.name + ' is due for update', telemetryConstants.severity.SEVERITY_INFO);
        licenseStatus = Enum.DeploymentStatus.UpdateAvailable;
      }

      let endUserLink: string = getEndUserLaunchLink(
        this.props.solution,
        this.state.apiUrl,
        this.props.solution.additionalDeploymentParametersAvailable
      );

      return (
        <div className={styles.solutionStatusContainer}>
          {!isLicensePresent ? (
            <div onClick={this.onLicenseMissingClicked}>
              <LicenseMissingStatus
                top={topMargin(this.context)}
                left={responsiveLicenseStatusBar(Enum.DeploymentStatus[`${licenseStatus}`])}
              />
            </div>
          ) : null}
          {isMultiPackagePresent ? null : (
            <ActionButton
              onClick={() =>
                this.externalLinkClickHandler(telemetryConstants.events.LAUNCH_SOLUTION_CLICKED, endUserLink)
              }
              className={responsiveStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.Completed], this.context)}
              iconProps={{ iconName: 'OpenInNewWindow' }}
            >
              <FormattedMessage id={this.returnUserLaunchName()} />
            </ActionButton>
          )}
          {updateAvailable && (
            <UpdateAvailableStatus
              top={topMargin(this.context)}
              left={responsiveStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.UpdateAvailable], this.context)}
            />
          )}
        </div>
      );
    } else if (
      status === Enum.DeploymentStatus[Enum.DeploymentStatus.InProgress] ||
      status === Enum.DeploymentStatus[Enum.DeploymentStatus.NotStarted]
    ) {
      telemetry.logTrace(
        this.props.solution.name + ' deployment is in progress',
        telemetryConstants.severity.SEVERITY_INFO
      );
      return (
        <div className={styles.solutionStatusContainer}>
          {!isLicensePresent ? (
            <div onClick={this.onLicenseMissingClicked}>
              <LicenseMissingStatus
                top={topMargin(this.context)}
                left={responsiveLicenseStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.InProgress], this.context)}
              />
            </div>
          ) : null}
          <InProgressStatus
            top={topMargin(this.context)}
            left={responsiveStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.InProgress], this.context)}
          />
        </div>
      );
    } else if (status === Enum.DeploymentStatus[Enum.DeploymentStatus.Failure]) {
      telemetry.logTrace(this.props.solution.name + ' deployment failed', telemetryConstants.severity.SEVERITY_ERROR);
      return (
        <div className={styles.solutionStatusContainer}>
          {!isLicensePresent ? (
            <div onClick={this.onLicenseMissingClicked}>
              <LicenseMissingStatus
                top={topMargin(this.context)}
                left={responsiveLicenseStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.Failure], this.context)}
              />
            </div>
          ) : null}
          <DeploymentFailedStatus
            top={topMargin(this.context)}
            left={responsiveStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.Failure], this.context)}
          />
        </div>
      );
    }
  };

  render() {
    const {
      downArrowClicked,
      updateButtonClicked,
      isUpdateDialogOpen,
      updateConfirmed,
      termsChecked,
      licenseMissingClicked,
      preDeploymentDependencies,
      disableUpdateButton,
      isFetchingDependencies,
    } = this.state;

    const { deployStatus, intl, solution, l01RowKey } = this.props;
    const preDependencies: ApiType.L03Dependency[] | undefined = solution.l03Dependencies?.filter(
      (x: ApiType.L03Dependency) => x.preOrPostDeploy === 'Pre' && x.type !== AZURE
    );

    const isLicensePresent = Utils.productHasLicense(solution);
    return (
      <div>
        <div className={styles.solutionBox}>
          <div className={styles.solutionTitleContainer}>
            <span className={styles.solutionTitleWithoutVersion}>{solution.name}</span>
            {this.renderDeploymentStatus(
              isLicensePresent,
              isMultiPackagePresent([solution]),
              deployStatus,
              solution?.isUpdateAvailable
            )}
            {downArrowClicked ? (
              <IconButton
                ariaLabel={intl.formatMessage({
                  id: 'deploymentDetails.button.upArrow',
                })}
                onClick={this.onUpButtonClick}
                className={styles.arrowStyle}
                iconProps={{ iconName: 'ChevronUp' }}
              ></IconButton>
            ) : preDependencies?.length !== 0 ||
              solution?.isUpdateAvailable === true ||
              isMultiPackagePresent([solution]) ? (
              <IconButton
                ariaLabel={intl.formatMessage({
                  id: 'deploymentDetails.button.downArrow',
                })}
                onClick={this.onDownButtonClick}
                className={styles.arrowStyle}
                iconProps={{ iconName: 'ChevronDown' }}
              ></IconButton>
            ) : null}
          </div>

          {downArrowClicked &&
          deployStatus === Enum.DeploymentStatus[Enum.DeploymentStatus.Success] &&
          solution?.isUpdateAvailable === true ? (
            <UpdateDetails
              intl={intl}
              solution={solution}
              isFetchingDependencies={isFetchingDependencies}
              disableUpdateButton={disableUpdateButton}
              totalPreDeploymentDependencies={preDeploymentDependencies.length}
              onUpdateButtonClick={this.onUpdateButtonClick}
              preDeploymentDependencies={preDeploymentDependencies}
            />
          ) : downArrowClicked ? (
            <div>
              {isMultiPackagePresent([solution]) ? (
                <div id="environmentTable" className={successStyles.mainSectionContainer}>
                  {solution && <EnvironmentTable selectedOffer={solution} instanceApiUrl={this.state.apiUrl} />}
                </div>
              ) : null}
              <div className={styles.preDeploymentConfigurationContainer}>
                <Visible when={!isFetchingDependencies} fallback={<LoadingScreen isVisible={true} label={''} />}>
                  {preDeploymentDependencies.length !== 0 ? (
                    <PreDeploymentConfigurations
                      intl={intl}
                      solution={solution}
                      preDeploymentDependencies={preDeploymentDependencies}
                    />
                  ) : null}
                </Visible>
              </div>
            </div>
          ) : null}

          {updateButtonClicked ? (
            <UpdateDialog
              onDismiss={this.onDialogDismiss}
              onConfirmClick={this.onConfirmClick}
              isOpen={isUpdateDialogOpen}
              confirmed={updateConfirmed}
              onTermsChecked={this.onTermsChecked}
              termsChecked={termsChecked}
              l01RowKey={l01RowKey}
              intl={intl}
              solution={solution}
            />
          ) : null}
          {licenseMissingClicked && !isLicensePresent ? (
            <LearnMoreDialog
              missingLicenses={Utils.getProductMissingLicenses(solution)}
              isOpen={licenseMissingClicked}
              close={this.closeLearnMoreDialog}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default injectIntl(SolutionsContainer);
