import React, { FC, ReactElement } from 'react';
import InstanceComponentBuilder from '../azComponents/instanceComponentBuilder';
import * as Type from '../../../../../common/Type';
import AccountSubscription from '../azComponents/accountSubscription';
import ResourceGroups from '../azComponents/resourceGroups';
import { useDispatch } from 'react-redux';
import { setParameterDefinitionProvider } from '../../../../../redux/azureInstance/azureInstanceActions';
import { InstanceDetails } from '../azComponents/instanceDetails';
import { Stack } from '@fluentui/react';
import { stackTokens } from '../azComponents/customComponentControls/textFieldControl.styles';
import { ArmDeploymentScope } from '../../../../../solutionCenterApi/gen';
import Locations from '../azComponents/locations';

interface InitialProps {
  currentDeploymentScope: ArmDeploymentScope;
  armScopeToOffersMap: { [key in keyof typeof ArmDeploymentScope]?: string[] };
  parameterDefinitions: Type.ParameterDefinitionDictionary[];
}

export const AzureComponentBuilder: FC<InitialProps> = (props: InitialProps): ReactElement => {
  const dispatch = useDispatch();

  const handleParameterDefinitionsDictionary = (parameterDefinitionDictionary: Type.ParameterDefinitionDictionary) => {
    props.parameterDefinitions.forEach((eachDictionary) => {
      if (eachDictionary.azureOfferRowKey === parameterDefinitionDictionary.azureOfferRowKey) {
        eachDictionary.parameterDefinitions = parameterDefinitionDictionary.parameterDefinitions;
        dispatch(setParameterDefinitionProvider(props.parameterDefinitions));
      }
    });
  };

  return (
    <>
      {props.currentDeploymentScope === ArmDeploymentScope.RESOURCE_GROUP && (
        <Stack tokens={stackTokens}>
          <AccountSubscription />
          <ResourceGroups />
        </Stack>
      )}
      {props.currentDeploymentScope === ArmDeploymentScope.TENANT && (
        <Stack tokens={stackTokens}>
          <Locations />
        </Stack>
      )}
      {props.parameterDefinitions
        .filter((d) => d.armDeploymentScope === props.currentDeploymentScope)
        .map((dictionary, index) => (
          <Stack tokens={stackTokens} key={index}>
            <InstanceDetails azureOfferRowKey={dictionary.azureOfferRowKey} />
            <InstanceComponentBuilder
              armDeploymentScope={dictionary.armDeploymentScope}
              azureOfferRowKey={dictionary.azureOfferRowKey}
              parameterDefinitions={dictionary.parameterDefinitions}
              parameterDefinitionsDictionaryCallback={handleParameterDefinitionsDictionary}
              parameterDefinitionsCallback={null}
            />
          </Stack>
        ))}
    </>
  );
};
