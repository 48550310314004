import {
  SELECTED_AZURE_OFFERS,
  AzureInstanceDispatchTypes,
  PARAMETER_DEFINITION_PROVIDER,
  SUBSCRIPTION,
  RESOURCE_GROUP,
  LOCATION,
  ERROR_VALIDATION_CONTROL_TYPE,
  ERROR_VALIDATION_CHECK_RESOURCEGROUP_TYPE,
  ERROR_VALIDATION_CHECK_SUBSCRIPTION_TYPE,
  HASPERMISSIONAUTHORIZATION,
  ARM_DEPLOYMENT_SCOPE_PROVIDER,
} from './azureInstanceTypes';
import * as Type from '../../common/Type';
import * as ApiType from '../../solutionCenterApi/gen';
import {
  IAzureResourceGroup,
  IAzureSubscription,
  IAzureLocation,
} from '../../pages/install/destination/azureResourceWidget/models/azureResource.Models';
import { PermissionState } from '../../common/Enum';
import { ArmDeploymentScope } from '../../solutionCenterApi/gen';

interface DefaultStateI {
  selectedAzureOffers: ApiType.L03[] | undefined;
  armScopeToOffersMap: { [key in keyof typeof ArmDeploymentScope]?: string[] };
  parameterDefinitionProvider?: Type.ParameterDefinitionDictionary[];
  errorValidationControlType: boolean;
  errorValidationCheckResourceGroupType: boolean;
  errorValidationCheckSubscriptionType: boolean;
  subscription?: IAzureSubscription;
  resourceGroup?: IAzureResourceGroup;
  location?: IAzureLocation;
  hasPermissionAuthorization: PermissionState;
}

const initialState: DefaultStateI = {
  selectedAzureOffers: [],
  armScopeToOffersMap: {},
  errorValidationControlType: false,
  errorValidationCheckResourceGroupType: false,
  errorValidationCheckSubscriptionType: false,
  hasPermissionAuthorization: PermissionState.PROMPT,
};

const azureInstanceReducer = (
  state: DefaultStateI = initialState,
  action: AzureInstanceDispatchTypes
): DefaultStateI => {
  switch (action.type) {
    case SELECTED_AZURE_OFFERS:
      return {
        ...state,
        selectedAzureOffers: action.payload,
      };
    case ARM_DEPLOYMENT_SCOPE_PROVIDER:
      return {
        ...state,
        armScopeToOffersMap: action.payload,
      };
    case PARAMETER_DEFINITION_PROVIDER:
      return {
        ...state,
        parameterDefinitionProvider: action.payload,
      };
    case ERROR_VALIDATION_CONTROL_TYPE:
      return {
        ...state,
        errorValidationControlType: action.payload,
      };
    case ERROR_VALIDATION_CHECK_SUBSCRIPTION_TYPE:
      return {
        ...state,
        errorValidationCheckSubscriptionType: action.payload,
      };
    case ERROR_VALIDATION_CHECK_RESOURCEGROUP_TYPE:
      return {
        ...state,
        errorValidationCheckResourceGroupType: action.payload,
      };
    case SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };
    case RESOURCE_GROUP:
      return {
        ...state,
        resourceGroup: action.payload,
      };
    case LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case HASPERMISSIONAUTHORIZATION:
      return {
        ...state,
        hasPermissionAuthorization: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default azureInstanceReducer;
