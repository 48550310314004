import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import '@fluentui/react/dist/css/fabric.css';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './redux/configureStore';
import { Styler } from '@business-app/fabric/lib/styler';
import * as Theme from '@business-app/fabric/lib/utilities/themes';
import App from './App';
import { LocaleContextProvider } from './contexts/LocaleContext';
import { PersistStoreStateContainer } from './stateContainers/persistStoreStateContainer';
import { ResponsiveModeContextProvider } from './contexts/ResponsiveModeContext';
import { segoeRegular } from './common/Constants';

window.React = React;
window.ReactDOM = ReactDOM;
// Temporary stop service worker
//import registerServiceWorker from './registerServiceWorker';
const ThemeFontOverride = {
  defaultFontStyle: {
    fontFamily: segoeRegular,
    fontSize: '14px',
    lineHeight: '20px',
  },
};

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
let queryParams = new URLSearchParams(document.location.search);
let instanceId = queryParams.get('organizationId');
//telemetry.logTrace(telemetryConstants.events.APPSOURCE_INSTANCE_INSTALL_REQUEST + ' - ' + instanceId, telemetryConstants.severity.SEVERITY_INFO);
if (instanceId !== null) window.sessionStorage.setItem('instanceId', instanceId);
const peristedState = PersistStoreStateContainer.loadState();
const history = createBrowserHistory({ basename: baseUrl });
export const store = configureStore(history, peristedState);
store.subscribe(() => {
  PersistStoreStateContainer.saveState(store.getState());
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Styler theme={Theme.Dynamics365Theme} themeOverrides={{ ...ThemeFontOverride }}>
        <LocaleContextProvider>
          <ResponsiveModeContextProvider>
            <App />
          </ResponsiveModeContextProvider>
        </LocaleContextProvider>
      </Styler>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

//registerServiceWorker();
