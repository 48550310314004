import * as React from 'react';
import { contentStyles, updateDialogIconButtonStyles, footerStackTokens, modalStyle } from './update-dialog.styles';
import SolutionsStateContainer from '../../../stateContainers/solutionsStateContainer';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import {
  Checkbox,
  IconButton,
  Link,
  MessageBar,
  MessageBarType,
  Modal,
  Spinner,
  SpinnerSize,
  Stack,
} from '@fluentui/react';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import TermsDialog from '../../install/destination/dialogs/termsDialog';
import { useEffect } from 'react';
import { telemetry } from '../../../services/telemetryService';
import { PBITemplateAppPackageType } from '../../../common/Constants';
import { spinnerStyle } from '../deploymentDetails.styles';

type updateDialogProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onConfirmClick: () => void;
  confirmed: boolean;
  onTermsChecked: (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => void;
  termsChecked: boolean;
  solution: ApiType.L03 | ApiType.OptionalComponent;
  l01RowKey: string;
} & WrappedComponentProps;

export const UpdateDialog: React.FunctionComponent<updateDialogProps> = (props: updateDialogProps) => {
  const [hiddenDialog, setHiddenDialog] = React.useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [keys, setKeys] = React.useState<string[]>([]);

  const [onConfirmClicked, setonConfirmClickedValue] = React.useState<boolean>(false);
  const { isOpen, onDismiss, onConfirmClick, confirmed, onTermsChecked, termsChecked, intl, solution } = props;

  useEffect(() => {
    try {
      const parsedObj = solution.currentVersion?.description
        ? JSON.parse(solution.currentVersion.description)
        : undefined;
      const keys = Object.keys(parsedObj);
      setKeys(keys);
    } catch (error) {
      telemetry.logException(error);
    }
  }, []);

  const onConfirmedClick = (value: boolean) => {
    setonConfirmClickedValue(value);
    onConfirmClick();
  };

  const findTerms = (): ApiType.L01 => {
    const solutions: ApiType.L01[] = SolutionsStateContainer.getAllSolutions();
    var l01: ApiType.L01[] = solutions.filter((solution: ApiType.L01) => solution.rowKey === props.l01RowKey);
    return l01[0];
  };

  const renderLabelWithLink = () => {
    var l01 = findTerms();
    const termsLinkText: string = l01.termsLinkText
      ? l01.termsLinkText
      : props.intl.formatMessage({
          id: 'destination.complianceDisclaimer',
        });
    return (
      <span className={contentStyles.checkBox}>
        <FormattedMessage
          id="destination.termsPreLabel"
          values={{
            complianceDisclaimer: termsLinkText,
            Link: (termsLinkText: string) => (
              <Link
                tabIndex={0}
                underline={true}
                href={l01.termsLink !== null ? l01.termsLink : undefined}
                target={l01.termsLink !== null ? '_blank' : undefined}
                rel={l01.termsLink !== null ? 'noopener noreferrer' : undefined}
                onClick={l01.termsLink === null ? () => setHiddenDialog(false) : undefined}
              >
                {termsLinkText}
              </Link>
            ),
          }}
        />
      </span>
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName={contentStyles.container}
        isBlocking={true}
        styles={modalStyle}
      >
        <div className={contentStyles.header}>
          {onConfirmClicked && !confirmed && (
            <Spinner
              size={SpinnerSize.large}
              label={intl.formatMessage({ id: 'loading.pleaseWait' })}
              styles={spinnerStyle}
            />
          )}
          <span>
            {!confirmed
              ? intl.formatMessage({ id: 'updateDialog.confirmUpdate' })
              : intl.formatMessage({ id: 'updateDialog.updateInProgress' })}
          </span>
          <IconButton
            styles={updateDialogIconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            onClick={onDismiss}
            disabled={onConfirmClicked && !confirmed}
          />
        </div>
        {!confirmed ? (
          <div className={contentStyles.body}>
            <span>
              {solution?.instanceType === PBITemplateAppPackageType && <FormattedMessage id="pbi_update_name" />}
            </span>
            <br />
            {solution?.instanceType !== PBITemplateAppPackageType ? (
              <div></div>
            ) : (
              <div>
                <br />
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                  {intl.formatMessage({ id: 'pbi_update_description' })}
                </MessageBar>
              </div>
            )}
            <div className={contentStyles.checkBoxContainer}>
              {!(onConfirmClicked && !confirmed) && (
                <Checkbox onChange={onTermsChecked} onRenderLabel={renderLabelWithLink} />
              )}
            </div>
          </div>
        ) : (
          <div className={contentStyles.updateContainer}>
            <div>
              <span>
                <FormattedMessage id="updateDialog.description" />
              </span>
            </div>
            <br />
            <div className={contentStyles.imageContainer}>
              <img className={contentStyles.loadingImage} src="../../../assets/images/loading_animation.gif" alt="" />
            </div>
          </div>
        )}
        <Stack
          className={!confirmed ? contentStyles.footer : contentStyles.confirmedFooter}
          horizontal
          horizontalAlign="end"
          tokens={footerStackTokens}
        >
          {!confirmed ? (
            <PrimaryButton
              disabled={!termsChecked || onConfirmClicked}
              onClick={() => onConfirmedClick(true)}
              text={intl.formatMessage({ id: 'buttons.confirm' })}
            />
          ) : (
            <PrimaryButton
              onClick={onDismiss}
              className={contentStyles.okayButtonStyle}
              text={intl.formatMessage({ id: 'buttons.ok' })}
            />
          )}
          {!confirmed ? (
            <DefaultButton
              disabled={onConfirmClicked && !confirmed}
              className={contentStyles.cancelButton}
              onClick={onDismiss}
              text={intl.formatMessage({ id: 'buttons.cancel' })}
            />
          ) : null}
        </Stack>
        {hiddenDialog === false ? (
          <TermsDialog hidden={hiddenDialog} handleDialog={() => setHiddenDialog(true)} termsHtml={findTerms().terms} />
        ) : null}
      </Modal>
    </>
  );
};
