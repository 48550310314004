import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@fluentui/react';
import { FaPlay } from 'react-icons/fa';
import config from '../../../config';
import setupStyles from './setupGuideBlock.styles';

const fallbackThumbnail = '../../../../public/assets/icon/Favicon_32px.png';

export interface SetupGuideBlockProps {
  title: string;
  description: string;
  thumbnailLink: string;
  videoLink: string;
}

export const SetupGuideBlock: React.FC<SetupGuideBlockProps> = ({ title, description, thumbnailLink, videoLink }) => {
  const history = useHistory();
  const navigateToSetupGuides = () => {
    const encodedTitle = title.toLowerCase().replace(/\s+/g, '-');
    const route = `${config.routes.setupGuides.replace(':title', encodedTitle)}`;
    history.push({
      pathname: route,
      state: { title, description, thumbnailLink, videoLink },
    });
  };

  const renderThumbnail = () => {
    if (thumbnailLink) {
      return (
        <iframe
          src={thumbnailLink}
          width="140"
          height="80"
          title="Setup Guide"
          scrolling="no"
          frameBorder="0"
          draggable="false"
        ></iframe>
      );
    } else {
      return <img src={fallbackThumbnail} alt="Fallback Thumbnail" />;
    }
  };

  return (
    <div className={setupStyles.courseBlock}>
      <div className={setupStyles.courseThumbnail}>{renderThumbnail()}</div>
      <div className={setupStyles.courseInfo}>
        <h3>{title}</h3>
        <p className={setupStyles.courseDescription}>{description}</p>
      </div>
      <div>
        <Button onClick={navigateToSetupGuides} className={setupStyles.learningButton}>
          <span className={setupStyles.learningButtonIcon}>
            <FaPlay />
          </span>
          <span>Start Learning</span>
        </Button>
      </div>
    </div>
  );
};

export default SetupGuideBlock;
