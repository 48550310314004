import { mergeStyleSets } from '@fluentui/react';

const successStyles = mergeStyleSets({
  topSection: {
    width: '75%',
    display: 'inline-block',
    '@media(max-width: 960px)': {
      width: '95%',
    },
    paddingBottom: '34px',
  },
  topSectionContainer: {
    display: 'inline',
    paddingLeft: '0px',
  },
  topSectionTitle: {
    lineHeight: '28px',
    paddingBottom: '4px',
    paddingTop: '32px',
    marginTop: '0px',
  },
  topSectionSecondaryTitle: {
    lineHeight: '20px',
  },
  mainSectionContainer: {
    maxWidth: '95%',
  },
  dependencyListContainer: {
    marginLeft: '-10px',
  },
});

export default successStyles;
