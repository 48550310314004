import * as React from 'react';
import { FC, ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styles, { cardTokens, cardStyles, sectionStackTokens, stackStyles } from './yourDeployments.styles';
import LoadingScreen from '../../../common/loading-screen';
import { UpdateAvailableStatus } from '../../../components/statusBars/updateAvailableStatus';
import { InProgressStatus } from '../../../components/statusBars/inProgressStatus';
import { DeploymentFailedStatus } from '../../../components/statusBars/deploymentFailedStatus';
import { telemetry } from '../../../services/telemetryService';
import config, { telemetryConstants } from '../../../config';
import { Stack, Link } from 'office-ui-fabric-react';
import { FormattedMessage, WrappedComponentProps, injectIntl, useIntl } from 'react-intl';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import { Card } from '@uifabric/react-cards';
import Utils from '../../../utils/index';
import * as Enum from '../../../common/Enum';
import Visible from '../../../components/visible/visible';
import { ResponsiveList } from '../../../common/responsiveList';
import { EarlyAccessAvailableStatus } from '../../../components/statusBars/earlyAccessAvailableStatus';
import {
  AdvancedDropdownProps,
  AdvancedFilterSection,
} from '../../../components/advancedFilters/advancedFilterSection';
import {
  deploymentStatusDropDownOptionKeys,
  modifiedByDropDownOptionKeys,
} from '../../../components/advancedFilters/constants/advancedFilterConstants';
import { SelectedFiltersDisplaySection } from '../../../components/advancedFilters/selectedFiltersDisplaySection/selectedFiltersDisplaySection';
import { applyDeploymentsFilters } from '../../../components/advancedFilters/AdvancedFilterUtils';
import { SearchComponent } from '../../../components/advancedFilters/searchComponent';
import { SortButton, SortButtonProp } from '../../../components/sortButton/sortButton';
import { applySortDeployments } from '../../../utils/helper';
import { ListView, ListViewProp } from '../../../components/gridListViews/listView/listView';
import { ListViewColumnFieldNames } from '../../../common/Enum';
import { IColumn } from '@fluentui/react';
import { DeploymentSuccessStatus } from '../../../components/statusBars/deploymentSuccessStatus';
import GridListToggleButton, {
  GridListToggleButtonProp,
} from '../../../components/gridListViews/gridListToggleButton/gridListToggleButton';
import SolutionsStateContainer from '../../../stateContainers/solutionsStateContainer';

type yourDeploymentProps = {
  history: RouteComponentProps['history'];
  deployments: Array<ApiType.DeploymentDefinitionEntity>;
  announcements: Array<ApiType.Announcement>;
  loadingDeploymentDefinitions: boolean;
} & WrappedComponentProps;

const YourDeployments: FC<yourDeploymentProps> = (props: yourDeploymentProps): ReactElement => {
  const [deploymentStatusFilterKeys, setDeploymentStatusFilterKeys] = useState<string[]>(
    SolutionsStateContainer.getDeploymentStatus()
  );
  const [modifiedByFilterKeys, setModifiedByFilterKeys] = useState<string[]>(
    SolutionsStateContainer.getModifiedByFilterKeys()
  );
  const [searchText, setSearchText] = useState<string>(SolutionsStateContainer.getFilteredSearchKeyword());
  const setSearch = (searchText: string) => {
    setSearchText(searchText);
    SolutionsStateContainer.setFilteredSearchKeyword(searchText);
  };
  const intlShape = useIntl();
  const [filteredDeploymentList, setFilteredDeploymentList] = useState<Array<ApiType.DeploymentDefinitionEntity>>(
    props.deployments
  );
  const [selectedSort, setSelectedSort] = useState<string>(SolutionsStateContainer.getFilteredSelectedSort());
  const [isGridView, setGridListView] = useState<boolean>(SolutionsStateContainer.getGridListView());
  const setGridList = (isGridView: boolean) => {
    setGridListView(isGridView);
    SolutionsStateContainer.setGridListView(isGridView);
  };
  const gridListToggleButtonProp: GridListToggleButtonProp = {
    setGridListView: setGridList,
    isGridView: isGridView,
  };

  const deploymentStatusFilterOptions = [
    {
      key: deploymentStatusDropDownOptionKeys.successKey,
      text: intlShape.formatMessage({ id: 'deploymentStatusDropDownOptionTexts.successText' }),
    },
    {
      key: deploymentStatusDropDownOptionKeys.updatesAvailableAppKey,
      text: intlShape.formatMessage({ id: 'deploymentStatusDropDownOptionTexts.updatesAvailableText' }),
    },
    {
      key: deploymentStatusDropDownOptionKeys.inProgressKey,
      text: intlShape.formatMessage({ id: 'deploymentStatusDropDownOptionTexts.inProgressText' }),
    },
    {
      key: deploymentStatusDropDownOptionKeys.failedKey,
      text: intlShape.formatMessage({ id: 'deploymentStatusDropDownOptionTexts.failedText' }),
    },
  ];

  const modifiedByFilterOptions = [
    {
      key: modifiedByDropDownOptionKeys.selfKey,
      text: intlShape.formatMessage({ id: 'modifiedByDropDownOptionTexts.self' }),
    },
    {
      key: modifiedByDropDownOptionKeys.othersKey,
      text: intlShape.formatMessage({ id: 'modifiedByDropDownOptionTexts.others' }),
    },
  ];

  const setDeploymentStatusFilter = (deploymentStatusFilterKeys: string[]) => {
    setDeploymentStatusFilterKeys(deploymentStatusFilterKeys);
    SolutionsStateContainer.setDeploymentStatus(deploymentStatusFilterKeys);
  };

  const setModifiedByFilter = (modifiedByFilterKeys: string[]) => {
    setModifiedByFilterKeys(modifiedByFilterKeys);
    SolutionsStateContainer.setModifiedByFilterKeys(modifiedByFilterKeys);
  };

  const deploymentDropdownProps: AdvancedDropdownProps[] = [
    {
      setFilter: setDeploymentStatusFilter,
      filterKeys: deploymentStatusFilterKeys,
      filterDropDownOptions: deploymentStatusFilterOptions,
      placeHolder: intlShape.formatMessage({ id: 'deploymentStatusFilterPlaceholder' }),
    },
    {
      setFilter: setModifiedByFilter,
      filterKeys: modifiedByFilterKeys,
      filterDropDownOptions: modifiedByFilterOptions,
      placeHolder: intlShape.formatMessage({ id: 'modifiedByFilterPlaceholder' }),
    },
  ];

  useEffect(() => {
    setFilteredDeploymentList(
      applySortDeployments(
        selectedSort,
        props.deployments.filter((deployment) =>
          applyDeploymentsFilters(deployment, { deploymentStatusFilterKeys, modifiedByFilterKeys }, searchText)
        )
      )
    );
  }, [props.deployments, deploymentStatusFilterKeys, modifiedByFilterKeys, searchText, selectedSort]);

  const onDeploymentCardClick = (nameOfDeployment: string, index: number) => {
    telemetry.logTrace(
      'User selected ' + nameOfDeployment + ' deployment card',
      telemetryConstants.severity.SEVERITY_INFO
    );
    props.history.push({
      pathname: config.routes.deploymentDetails,
      state: { deploymentDefinition: filteredDeploymentList[index as number] },
    });
  };

  const renderDeploymentStatus = (status?: string, l01RowKey?: string, updateAvailable?: boolean) => {
    if (status === Enum.DeploymentStatus[Enum.DeploymentStatus.Success]) {
      if (updateAvailable) {
        if (props.announcements.find((element) => element.l01RowKey === l01RowKey)) {
          return <EarlyAccessAvailableStatus top={'0px'} left={'0px'} />;
        }
        return <UpdateAvailableStatus top={'0px'} left={'0px'} />;
      }
      return <DeploymentSuccessStatus />;
    } else if (
      status === Enum.DeploymentStatus[Enum.DeploymentStatus.InProgress] ||
      status === Enum.DeploymentStatus[Enum.DeploymentStatus.NotStarted]
    ) {
      return <InProgressStatus top={'0px'} left={'0px'} />;
    } else if (status === Enum.DeploymentStatus[Enum.DeploymentStatus.Failure]) {
      return <DeploymentFailedStatus top={'0px'} left={'0px'} />;
    }
  };

  const deploymentCard = (o: ApiType.DeploymentDefinitionEntity, i: number): JSX.Element => {
    return (
      <Card key={o.rowKey} onClick={() => onDeploymentCardClick(o.name, i)} styles={cardStyles} tokens={cardTokens}>
        <Card.Item fill className={styles.cardItemStyle}>
          <div className={styles.headerStyle}>
            <div className={styles.imgNameStyle}>
              <img
                src={!o.l01Icon ? Utils.getIconSvg('healthcare_for_provider', 'icons') : o.l01Icon}
                className={styles.imageStyle}
                height="36px"
                width="36px"
                alt={props.intl.formatMessage({ id: 'altText.yourDeployments.icon' }, { deploymentTitle: o.name })}
              />
              <span className={styles.titleStyle}>{o.name}</span>
            </div>
            <div className={styles.instanceNameStyle}>
              <span title={Utils.getInstanceName(o)} className={styles.descriptionStyle}>
                {o.l01Name} | {Utils.getInstanceName(o)}
              </span>
            </div>
          </div>
        </Card.Item>
        <Card.Section grow className={styles.cardFooterStyle}>
          {renderDeploymentStatus(o.deploymentStatus, o.l01RowKey, o.isUpdateAvailable)}
        </Card.Section>
      </Card>
    );
  };

  const sortOptions: Enum.SortOptionsL03s[] = [
    Enum.SortOptionsL03s.SortNewest,
    Enum.SortOptionsL03s.SortOldest,
    Enum.SortOptionsL03s.SortAscending,
    Enum.SortOptionsL03s.SortDescending,
  ];
  const setSort = (selectedSort: string) => {
    setSelectedSort(selectedSort);
    SolutionsStateContainer.setFilteredSelectedSort(selectedSort);
  };
  const sortProp: SortButtonProp = {
    setSelectedSort: setSort,
    sortOptions: sortOptions,
    selectedSort: selectedSort,
  };

  const columnsData: IColumn[] = [
    {
      key: 'deploymentName',
      name: intlShape.formatMessage({ id: 'listViewDeployment.deploymentName' }),
      fieldName: ListViewColumnFieldNames.DeploymentName,
      minWidth: 200,
      maxWidth: 700,
      calculatedWidth: 0.4,
      isResizable: true,
      isSorted: false,
      isSortedDescending: true,
      onRender: (item) => (
        <div
          onClick={() => onDeploymentCardClick(item.DeploymentName, item.Index)}
          className={styles.listViewColumnDeploymentName}
          title={item.DeploymentName}
        >
          <span>{item.DeploymentName}</span>
        </div>
      ),
    },
    {
      key: 'environmentName',
      name: intlShape.formatMessage({ id: 'listViewDeployment.environmentName' }),
      fieldName: ListViewColumnFieldNames.EnvironmentName,
      minWidth: 200,
      maxWidth: 800,
      calculatedWidth: 0.4,
      isResizable: true,
      onRender: (item) => (
        <div className={styles.listViewColumnEnvironmentName} title={item.EnvironmentName}>
          <span>{item.EnvironmentName}</span>
        </div>
      ),
    },
    {
      key: 'lastModified',
      name: intlShape.formatMessage({ id: 'listViewDeployment.lastModified' }),
      fieldName: ListViewColumnFieldNames.LastModified,
      minWidth: 200,
      maxWidth: 200,
      isResizable: false,
      isSorted: false,
      isSortedDescending: true,
      onRender: (item) => (
        <div className={styles.listViewColumnLastModified}>
          <span>{item.LastModified.date}</span>
        </div>
      ),
    },
    {
      key: 'status',
      name: intlShape.formatMessage({ id: 'listViewDeployment.status' }),
      fieldName: ListViewColumnFieldNames.Status,
      minWidth: 200,
      maxWidth: 200,
      isResizable: false,
      onRender: (item) => (
        <div>
          <span>{item.Status}</span>
        </div>
      ),
    },
  ];

  const itemsData: any[] = [];
  filteredDeploymentList?.map((deployment: ApiType.DeploymentDefinitionEntity, index: number) => {
    const deploymentName = deployment.name;
    const environmentName = deployment.instanceName;
    let modifiedDate = '';
    const timestamp = new Date(deployment.timestamp);
    const currentDateTime = new Date();
    const timeDifference = Utils.getTimeDifferenceInDays(currentDateTime, timestamp);

    if (timeDifference < 1) {
      const hoursAgo = Utils.getTimeDifferenceInHours(currentDateTime, timestamp);
      modifiedDate = intlShape.formatMessage({ id: 'listViewDeployment.hoursAgo' }, { hoursAgo: hoursAgo });
    } else if (timeDifference < 2) {
      const yesterdayTime = timestamp.toLocaleString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
      modifiedDate = intlShape.formatMessage({ id: 'listViewDeployment.yesterday' }, { yesterdayTime: yesterdayTime });
    } else {
      const currentYear = new Date().getFullYear();
      let options: Intl.DateTimeFormatOptions;
      if (timestamp.getFullYear() === currentYear) {
        options = { month: 'short', day: 'numeric' };
      } else {
        options = { month: 'short', day: 'numeric', year: 'numeric' };
      }
      modifiedDate = timestamp.toLocaleDateString(undefined, options);
    }

    const modifiedDateData = { date: modifiedDate, data: new Date(deployment.timestamp) };
    const status = renderDeploymentStatus(
      deployment.deploymentStatus,
      deployment.l01RowKey,
      deployment.isUpdateAvailable
    );
    itemsData.push({
      id: deployment.rowKey,
      DeploymentName: deploymentName,
      EnvironmentName: environmentName,
      LastModified: modifiedDateData,
      Status: status,
      Index: index,
    });
    return true;
  });

  const listProp: ListViewProp = {
    itemsData: itemsData,
    columnsData: columnsData,
    selectedSort: selectedSort,
    setSelectedSort: setSelectedSort,
  };

  const gridView = (): JSX.Element => {
    return (
      <>
        <Stack horizontal wrap tokens={sectionStackTokens} styles={stackStyles}>
          <ResponsiveList<ApiType.DeploymentDefinitionEntity>
            items={filteredDeploymentList}
            showMoreLinkStyle={styles.showMoreLink}
            renderItem={deploymentCard}
          />
        </Stack>
      </>
    );
  };
  return (
    <>
      {!props.loadingDeploymentDefinitions && props.deployments?.length === 0 ? (
        <div className={styles.yourDeploymentsContainer}>
          <span className={styles.yourDeployments}>
            <FormattedMessage id="navMenu.deployments" />
          </span>
          <Visible when={!props.loadingDeploymentDefinitions} fallback={<LoadingScreen label={''} isVisible={true} />}>
            <span className={styles.deploymentsDescription}>
              <FormattedMessage
                id="home.noDeployments"
                values={{
                  Link: (noDeploymentText: string) => (
                    <Link underline={true} href="/">
                      {noDeploymentText}
                    </Link>
                  ),
                }}
              />
            </span>
          </Visible>
        </div>
      ) : (
        <div className={styles.yourDeploymentsContainer}>
          <span className={styles.yourDeployments}>
            <FormattedMessage id="navMenu.deployments" />
          </span>
          <Visible when={!props.loadingDeploymentDefinitions} fallback={<LoadingScreen label={''} isVisible={true} />}>
            <span className={styles.deploymentsDescription}>
              <FormattedMessage id="home.existingDeployments.Description" />
            </span>
            <br />
            {
              <>
                <div className={styles.filterSectionContainer}>
                  <div className={styles.searchComponentContainer}>
                    <SearchComponent setSearchText={setSearch} searchText={searchText} />
                  </div>
                  <AdvancedFilterSection dropdownProps={deploymentDropdownProps} />
                  <div className={styles.gridListButtonContainer}>
                    <GridListToggleButton prop={gridListToggleButtonProp} />
                  </div>
                  <div className={styles.sortButtonContainer}>
                    <SortButton sortProp={sortProp} />
                  </div>
                </div>
                <br />
                <SelectedFiltersDisplaySection selectedFiltersDisplaySectionProps={deploymentDropdownProps} />
              </>
            }
            {!isGridView && <ListView prop={listProp} />}
            {isGridView && gridView()}
          </Visible>
        </div>
      )}
    </>
  );
};

export default injectIntl(YourDeployments);
