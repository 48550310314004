import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { StaticContext } from 'react-router';
import Configuration from './configuration';
import Installation from './installation';
import InstallationStateContainer from '../../stateContainers/installationStateContainer';
import WizardStep from '../../common/wizard-step/WizardStepComponent.jsx';
import { StepStatus } from '../../common/wizard-step/stepStatus';
import PubSub from '../../pubsub';
import { Success } from './success/success';
import config, { telemetryConstants } from '../../config';
import styles from './install.styles';
import SolutionsStateContainer from '../../stateContainers/solutionsStateContainer';
import DeploymentStateContainer from '../../stateContainers/deploymentStateContainer';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { telemetry } from '../../services/telemetryService';
import * as ApiType from '../../solutionCenterApi/gen/index';
import { IconButton } from 'office-ui-fabric-react';
import { AdditionalComponents } from './additionalComponents/additionalComponents';
import { TelemetryPropertyNames } from '../../common/Constants';
import * as Util from '../../utils/helper';
import { ResponsiveModeContext } from '../../contexts/ResponsiveModeContext';
import { ResponsiveMode } from '../../common/responsiveMode';
import destinationAzure from './destination/destination-azure';
import destinationDataverse from './destination/destination-dataverse';
import destinationCi from './destination/destination-ci';
import {
  AvailablePackageTypeKey,
  createDeploymentOverview,
  getAvailablePackageTypes,
  getRouteUtil,
} from '../../utils/deployHelper';
import deploymentSummary from './summary/deployment-summary';
import RuntimeConfigStateContainer from '../../stateContainers/runtimeConfigStateContainer';
import { ArmDeploymentScope } from '../../solutionCenterApi/gen/index';
import destinationAzureTenant from './destination/destination-azure-tenant';
import { getOCsFromSelectedOffers } from '../../utils/installHelper';
import { connect } from 'react-redux';
import { RootStore } from '../../redux';

export interface InstallProps
  extends RouteComponentProps<{}, StaticContext, { from: { pathname: string } | string }>,
    WrappedComponentProps {
  selectedOptionalComponents: Array<ApiType.OptionalComponent>;
}

export interface InstallState {
  armScopeToOffersMap: { [key in keyof typeof ArmDeploymentScope]?: string[] };
  selectedOffers: Array<ApiType.L03>;
  previewSelected: boolean;
  allSuccess: boolean;
  additionalComponentsSuccess: string;
  destinationStatus: string;
  configurationStatus: string;
  installationStatus: string;
  successStatus: string;
  inProgressStepper: string;
  inProgressStepperStatus: string;
  downArrowClicked: boolean;
  dataverseDestStatus: string;
  azureDestStatus: string;
  azureTenantDestStatus: string;
  ciDestStatus: string;
  overviewDestStatus: string;
}

export const mapStateToProps = (state: RootStore) => ({
  selectedOptionalComponents: state.deployment.selectedOptionalComponents,
});

class Install extends Component<InstallProps, InstallState> {
  urlChangeUnlisten: Function | null = null;
  subscriptionId: string | null = null;
  static contextType = ResponsiveModeContext;
  runtimeConfig = RuntimeConfigStateContainer.getConfiguration();

  constructor(props: InstallProps) {
    super(props);
    this.state = {
      armScopeToOffersMap: InstallationStateContainer.getArmDeploymentScopes(),
      selectedOffers: InstallationStateContainer.getSelectedOffers(),
      previewSelected: InstallationStateContainer.getPreviewSelectionStatus(),
      allSuccess: false,
      additionalComponentsSuccess: StepStatus.Empty,
      destinationStatus: StepStatus.Empty,
      configurationStatus: StepStatus.Empty,
      installationStatus: StepStatus.Empty,
      successStatus: StepStatus.Empty,
      inProgressStepper: this.renderAdditionalComponentStep()
        ? 'installStepper.AdditionalComponents'
        : 'destination.introductionTitle',
      inProgressStepperStatus: StepStatus.InProgress,
      downArrowClicked: false,
      dataverseDestStatus: StepStatus.Empty,
      azureDestStatus: StepStatus.Empty,
      azureTenantDestStatus: StepStatus.Empty,
      ciDestStatus: StepStatus.Empty,
      overviewDestStatus: StepStatus.Empty,
    };
  }

  checkPreviousLocation = () => {
    var customProps: Record<string, string> = {};
    if (!this.props.location.state?.from) {
      customProps[TelemetryPropertyNames.requestedLocation] = this.props.location.pathname;
      telemetry.logEvents(telemetryConstants.events.INSTALL_PAGE_REQUEST_DENIED, customProps);
      this.props.history.push(config.routes.home);
    }
  };

  componentDidMount = () => {
    this.urlChangeUnlisten = this.props.history.listen((location) => {
      this.updateWizardProgress(location);
    });
    this.subscriptionId = PubSub.subscribe('installationSuccess', () => {
      this.setState(
        {
          ...this.state,
          additionalComponentsSuccess: StepStatus.Finished,
          destinationStatus: StepStatus.Finished,
          configurationStatus: StepStatus.Finished,
          installationStatus: StepStatus.Finished,
          successStatus: StepStatus.Finished,
          dataverseDestStatus: StepStatus.Finished,
          azureDestStatus: StepStatus.Finished,
          azureTenantDestStatus: StepStatus.Finished,
          ciDestStatus: StepStatus.Finished,
          overviewDestStatus: StepStatus.Finished,
          allSuccess: true,
        },
        () => {
          this.props.history.push(config.routes.installSuccess, {
            from: this.props.location.pathname,
          });
        }
      );
    });
    this.updateWizardProgress(this.props.location);
  };

  componentWillUnmount = () => {
    this.urlChangeUnlisten!();
    PubSub.unsubscribe('installationSuccess', this.subscriptionId);
  };

  updateWizardProgress = ({ pathname, state }: { pathname: any; state?: any }) => {
    const hasError = state?.hasError;
    const optionalComponentsAvailable =
      Util.availableOptionalComponents() || DeploymentStateContainer.getOptionalComponents().length !== 0;

    switch (pathname) {
      case config.routes.installAdditionalComponents:
        this.setState({
          additionalComponentsSuccess: StepStatus.InProgress,
          destinationStatus: StepStatus.Empty,
          dataverseDestStatus: StepStatus.Empty,
          azureDestStatus: StepStatus.Empty,
          azureTenantDestStatus: StepStatus.Empty,
          ciDestStatus: StepStatus.Empty,
          overviewDestStatus: StepStatus.Empty,
          configurationStatus: StepStatus.Empty,
          installationStatus: StepStatus.Empty,
          inProgressStepper: 'installStepper.AdditionalComponents',
        });
        break;
      case config.routes.installDestinationDataverse:
        this.setState({
          additionalComponentsSuccess: optionalComponentsAvailable ? StepStatus.Finished : StepStatus.Empty,
          destinationStatus: StepStatus.InProgress,
          dataverseDestStatus: StepStatus.InProgress,
          azureDestStatus: StepStatus.Empty,
          azureTenantDestStatus: StepStatus.Empty,
          ciDestStatus: StepStatus.Empty,
          overviewDestStatus: StepStatus.Empty,
          configurationStatus: StepStatus.Empty,
          installationStatus: StepStatus.Empty,
          inProgressStepper: 'destination.introductionTitle',
        });
        break;
      case config.routes.installDestinationAzure:
        this.setState({
          additionalComponentsSuccess: optionalComponentsAvailable ? StepStatus.Finished : StepStatus.Empty,
          destinationStatus: StepStatus.InProgress,
          dataverseDestStatus: StepStatus.Finished,
          azureDestStatus: StepStatus.InProgress,
          azureTenantDestStatus: StepStatus.Empty,
          ciDestStatus: StepStatus.Empty,
          overviewDestStatus: StepStatus.Empty,
          configurationStatus: StepStatus.Empty,
          installationStatus: StepStatus.Empty,
          inProgressStepper: 'installStepper.AdditionalComponents',
        });
        break;
      case config.routes.installDestinationAzureTenant:
        this.setState({
          additionalComponentsSuccess: optionalComponentsAvailable ? StepStatus.Finished : StepStatus.Empty,
          destinationStatus: StepStatus.InProgress,
          dataverseDestStatus: StepStatus.Finished,
          azureDestStatus: StepStatus.Finished,
          azureTenantDestStatus: StepStatus.InProgress,
          ciDestStatus: StepStatus.Empty,
          overviewDestStatus: StepStatus.Empty,
          configurationStatus: StepStatus.Empty,
          installationStatus: StepStatus.Empty,
          inProgressStepper: 'destination.introductionTitle',
        });
        break;
      case config.routes.installDestinationCI:
        this.setState({
          additionalComponentsSuccess: optionalComponentsAvailable ? StepStatus.Finished : StepStatus.Empty,
          destinationStatus: StepStatus.InProgress,
          dataverseDestStatus: StepStatus.Finished,
          azureDestStatus: StepStatus.Finished,
          azureTenantDestStatus: StepStatus.Finished,
          ciDestStatus: StepStatus.InProgress,
          overviewDestStatus: StepStatus.Empty,
          configurationStatus: StepStatus.Empty,
          installationStatus: StepStatus.Empty,
          inProgressStepper: 'destination.introductionTitle',
        });
        break;
      case config.routes.installDeploymentSummary:
        this.setState({
          additionalComponentsSuccess: optionalComponentsAvailable ? StepStatus.Finished : StepStatus.Empty,
          destinationStatus: StepStatus.Finished,
          dataverseDestStatus: StepStatus.Finished,
          azureDestStatus: StepStatus.Finished,
          azureTenantDestStatus: StepStatus.Finished,
          ciDestStatus: StepStatus.Finished,
          overviewDestStatus: StepStatus.InProgress,
          configurationStatus: StepStatus.Empty,
          installationStatus: StepStatus.Empty,
          inProgressStepper: 'installStepper.deploySummary',
        });
        break;
      case config.routes.installConfigurationDetails:
        this.setState({
          additionalComponentsSuccess: optionalComponentsAvailable ? StepStatus.Finished : StepStatus.Empty,
          destinationStatus: StepStatus.Finished,
          dataverseDestStatus: StepStatus.Finished,
          azureDestStatus: StepStatus.Finished,
          azureTenantDestStatus: StepStatus.Finished,
          ciDestStatus: StepStatus.Finished,
          overviewDestStatus: StepStatus.Finished,
          configurationStatus: StepStatus.InProgress,
          installationStatus: StepStatus.Empty,
          inProgressStepper: 'installStepper.configureDependencies',
        });
        break;
      case config.routes.installInstallation:
        this.setState({
          additionalComponentsSuccess: optionalComponentsAvailable ? StepStatus.Finished : StepStatus.Empty,
          destinationStatus: StepStatus.Finished,
          dataverseDestStatus: StepStatus.Finished,
          azureDestStatus: StepStatus.Finished,
          azureTenantDestStatus: StepStatus.Finished,
          ciDestStatus: StepStatus.Finished,
          overviewDestStatus: StepStatus.Finished,
          configurationStatus: StepStatus.Finished,
          installationStatus: hasError ? StepStatus.Error : StepStatus.InProgress,
          inProgressStepper: 'installStepper.deploySolution',
          inProgressStepperStatus: this.state.installationStatus,
        });
        break;
      case config.routes.installSuccess:
        this.setState({
          additionalComponentsSuccess: optionalComponentsAvailable ? StepStatus.Finished : StepStatus.Empty,
          destinationStatus: StepStatus.Finished,
          dataverseDestStatus: StepStatus.Finished,
          azureDestStatus: StepStatus.Finished,
          azureTenantDestStatus: StepStatus.Finished,
          ciDestStatus: StepStatus.Finished,
          overviewDestStatus: StepStatus.Finished,
          configurationStatus: StepStatus.Finished,
          installationStatus: StepStatus.Finished,
          successStatus: StepStatus.Finished,
          allSuccess: true,
          inProgressStepper: 'installStepper.success',
          inProgressStepperStatus: this.state.successStatus,
        });
        break;
      default:
        break;
    }
  };

  getProductPageName = () => {
    const solution = SolutionsStateContainer.getCurrentSolution();
    return solution.solutionName;
  };

  handleBackClick = () => {
    const { pathname } = this.props.location;
    const { history } = this.props;
    let newPath: any = '';
    switch (pathname) {
      case config.routes.installAdditionalComponents:
        newPath = config.routes.home + this.getProductPageName();
        break;
      case config.routes.installDestinationDataverse:
        if (this.props.location.state?.from === config.routes.deploymentDetails) {
          history.goBack();
          break;
        } else if (
          Util.availableOptionalComponents() ||
          DeploymentStateContainer.getOptionalComponents().length !== 0
        ) {
          newPath = config.routes.installAdditionalComponents;
          break;
        } else {
          newPath = config.routes.home + this.getProductPageName();
          break;
        }
      case config.routes.installConfigurationDetails:
        newPath = config.routes.installDeploymentSummary;
        break;
      case config.routes.installInstallation:
        return;
      case config.routes.installDeploymentSummary:
        newPath = getRouteUtil(config.routes.installDeploymentSummary, false);
        newPath = this.modifyIfHomePath(newPath);
        break;
      case config.routes.installDestinationCI:
        newPath = getRouteUtil(config.routes.installDestinationCI, false);
        newPath = this.modifyIfHomePath(newPath);
        break;
      case config.routes.installDestinationAzure:
        newPath = getRouteUtil(config.routes.installDestinationAzure, false);
        newPath = this.modifyIfHomePath(newPath);
        break;
      default:
        history.push(config.routes.home);
        return;
    }
    this.updateWizardProgress(newPath);
    history.push(newPath, { from: pathname });
  };

  modifyIfHomePath = (newPath: string) => {
    if (newPath === config.routes.home) {
      newPath = config.routes.home + this.getProductPageName();
    }
    return newPath;
  };

  renderAdditionalComponentStep = (): boolean => {
    if (Util.availableOptionalComponents() || DeploymentStateContainer.getOptionalComponents().length !== 0) {
      return true;
    } else {
      return false;
    }
  };

  onDownButtonClick = () => {
    this.setState({
      downArrowClicked: true,
    });
  };

  onUpButtonClick = () => {
    this.setState({
      downArrowClicked: false,
    });
  };

  renderSubWizardSteps = (): JSX.Element => {
    const deploymentOverview = createDeploymentOverview(this.state.selectedOffers, this.state.armScopeToOffersMap);
    const availablePackageTypes = getAvailablePackageTypes(deploymentOverview);
    const selectedOffersOCs = getOCsFromSelectedOffers(this.state.selectedOffers);
    const deploymentOverviewOC = createDeploymentOverview(selectedOffersOCs, this.state.armScopeToOffersMap);
    const availablePackageTypesOC = getAvailablePackageTypes(deploymentOverviewOC);
    const isDisabledCondition = (type) => {
      return (
        !availablePackageTypes[String(type)] &&
        availablePackageTypesOC[String(type)] &&
        !getAvailablePackageTypes(
          createDeploymentOverview(this.props.selectedOptionalComponents, this.state.armScopeToOffersMap)
        )[String(type)]
      );
    };
    return (
      <>
        {availablePackageTypes.isDataverseAvailable || availablePackageTypesOC.isDataverseAvailable ? (
          <WizardStep
            description={<FormattedMessage id="installStepper.dataverseEnvironment" />}
            isLast={false}
            status={this.state.dataverseDestStatus}
            allSuccess={this.state.allSuccess}
            isIntermediateStep={true}
            isNextIntermediate={true}
            isDisabled={isDisabledCondition(AvailablePackageTypeKey.DATAVERSE)}
          />
        ) : null}
        {availablePackageTypes.isAzureAvailable || availablePackageTypesOC.isAzureAvailable ? (
          <WizardStep
            description={<FormattedMessage id="installStepper.azureEnvironment" />}
            isLast={false}
            status={this.state.azureDestStatus}
            allSuccess={this.state.allSuccess}
            isIntermediateStep={true}
            isNextIntermediate={true}
            isDisabled={isDisabledCondition(AvailablePackageTypeKey.AZURE)}
          />
        ) : null}
        {availablePackageTypes.isAzureTenantAvailable || availablePackageTypesOC.isAzureTenantAvailable ? (
          <WizardStep
            description={<FormattedMessage id="installStepper.azureTenantEnvironment" />}
            isLast={false}
            status={this.state.azureTenantDestStatus}
            allSuccess={this.state.allSuccess}
            isIntermediateStep={true}
            isNextIntermediate={true}
            isDisabled={isDisabledCondition(AvailablePackageTypeKey.AZURE_TENANT)}
          />
        ) : null}
        {availablePackageTypes.isCIAvailable || availablePackageTypesOC.isCIAvailable ? (
          <WizardStep
            description={<FormattedMessage id="installStepper.cIEnvironment" />}
            isLast={false}
            status={this.state.ciDestStatus}
            allSuccess={this.state.allSuccess}
            isIntermediateStep={true}
            isNextIntermediate={true}
            isDisabled={isDisabledCondition(AvailablePackageTypeKey.CI)}
          />
        ) : null}
        <WizardStep
          description={<FormattedMessage id="installStepper.deploySummary" />}
          isLast={false}
          status={this.state.overviewDestStatus}
          allSuccess={this.state.allSuccess}
        />
      </>
    );
  };

  renderWizardSteps = (): JSX.Element => {
    return (
      <>
        {this.renderAdditionalComponentStep() ? (
          <WizardStep
            description={<FormattedMessage id="installStepper.AdditionalComponents" />}
            isLast={false}
            status={this.state.additionalComponentsSuccess}
            allSuccess={this.state.allSuccess}
          />
        ) : null}
        <WizardStep
          description={<FormattedMessage id="destination.introductionTitle" />}
          isLast={false}
          status={this.state.destinationStatus}
          allSuccess={this.state.allSuccess}
          isNextIntermediate={true}
        />
        {this.renderSubWizardSteps()}
        <WizardStep
          description={<FormattedMessage id="installStepper.configureDependencies" />}
          isLast={false}
          status={this.state.configurationStatus}
          allSuccess={this.state.allSuccess}
        />
        <WizardStep
          description={
            this.state.previewSelected ? (
              <FormattedMessage id="installStepper.deployPreview" />
            ) : (
              <FormattedMessage id="installStepper.deploySolution" />
            )
          }
          isLast={false}
          status={this.state.installationStatus}
          allSuccess={this.state.allSuccess}
        />
        <WizardStep
          description={<FormattedMessage id="installStepper.success" />}
          isLast={true}
          status={this.state.successStatus}
          allSuccess={this.state.allSuccess}
        />{' '}
      </>
    );
  };

  render() {
    const { formatMessage } = this.props.intl;
    const backBtnLabel = formatMessage({ id: 'accessibility.wizardStepper.backAriaLabel' });
    const dropdownLabel = formatMessage({ id: 'navMenu.accessibility.CollapseExpandButton' });
    const displayInIFrame = !!sessionStorage['displayInIFrame'];
    return (
      <div className={styles.wizardContainer}>
        <div role="presentation" className={styles.wizardHeaderContainer}>
          <IconButton
            className={styles.wizardHeaderContainerIcon}
            iconProps={{ iconName: 'IncreaseIndentArrowMirrored', className: styles.iconIndentArrowMirroredFontSize }}
            ariaLabel={backBtnLabel}
            onClick={this.handleBackClick}
          />
          <h1>
            <div className={styles.wizardHeaderContainerTitle}>
              {this.state.previewSelected ? (
                <FormattedMessage id="installStepper.setUpPreview" />
              ) : this.state.selectedOffers.length > 1 ? (
                <FormattedMessage id="installStepper.setUpSolutions" />
              ) : (
                <FormattedMessage id="installStepper.setUpASolution" />
              )}
            </div>
          </h1>
        </div>

        {this.context === ResponsiveMode.Zoom && (
          <div className={styles.stepperDropDownContainer}>
            <div className={styles.stepperInProgress}>
              <WizardStep
                description={<FormattedMessage id={this.state.inProgressStepper} />}
                isLast={true}
                status={this.state.inProgressStepperStatus}
                allSuccess={this.state.allSuccess}
              />
            </div>
            <div className={styles.arrowButton}>
              {this.state.downArrowClicked ? (
                <IconButton
                  ariaLabel={dropdownLabel}
                  onClick={this.onUpButtonClick}
                  iconProps={{ iconName: 'ChevronUp' }}
                ></IconButton>
              ) : (
                <IconButton
                  ariaLabel={dropdownLabel}
                  onClick={this.onDownButtonClick}
                  iconProps={{ iconName: 'ChevronDown' }}
                ></IconButton>
              )}
            </div>

            <div className={styles.stepperDropDown}>{this.state.downArrowClicked && this.renderWizardSteps()}</div>
          </div>
        )}

        <div className={styles.stepPageContainer}>
          {this.context === ResponsiveMode.Desktop && (
            <div
              className={
                this.props.location.pathname !== '/install/select-destination'
                  ? `${styles.sideSteper} ${displayInIFrame ? styles.iframeContainer : ''}`
                  : `${styles.sideStepperDestination}`
              }
            >
              <div className={styles.innerSideSteper}>{this.renderWizardSteps()}</div>
            </div>
          )}
          <div className={styles.wizardContent}>
            <div className={styles.wizardContentInner}>
              <Switch>
                <Route path={config.routes.installAdditionalComponents} component={AdditionalComponents} />
                <Route path={config.routes.installDestinationAzure} component={destinationAzure} />
                <Route path={config.routes.installDestinationAzureTenant} component={destinationAzureTenant} />
                <Route path={config.routes.installDestinationDataverse} component={destinationDataverse} />
                <Route path={config.routes.installDestinationCI} component={destinationCi} />
                <Route path={config.routes.installConfigurationDetails} component={Configuration} />
                <Route path={config.routes.installInstallation} component={Installation} />
                <Route path={config.routes.installSuccess} component={Success} />
                <Route path={config.routes.installDeploymentSummary} component={deploymentSummary} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(injectIntl(Install));
