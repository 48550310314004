import {
  CDS_ENVIRONMENT,
  DeploymentDispatchTypes,
  SELECTED_CAPABILITY_INDEX,
  SELECTED_CAPABILITY_KEY,
  SHOW_ALL_L03S,
  REGION_BY_ORCHESTRATOR_TYPE,
  DEPLOYMENT_DEFINITION,
  CI_REGION_PAYLOAD,
  SELECTED_OPTIONAL_COMPONENT,
} from './deploymentTypes';
import * as Type from '../../common/Type';
import * as ApiType from '../../solutionCenterApi/gen/index';

interface DefaultStateI {
  cdsEnvironment: Type.Environment | ApiType.Instance | null;
  selectedCapabilityIndex: number | null;
  selectedCapabilityKey: string;
  showAllL03s: boolean;
  deploymentDefinition: ApiType.DeploymentDefinitionEntity | null;
  ciRegionPayload: ApiType.CIRegionPayload | null;
  regionsByOrchestratorType: Array<Type.RegionsByOrchestratorType>;
  selectedOptionalComponents: Array<ApiType.OptionalComponent>;
}

const initialState: DefaultStateI = {
  cdsEnvironment: null,
  selectedCapabilityIndex: null,
  selectedCapabilityKey: '',
  showAllL03s: false,
  deploymentDefinition: null,
  ciRegionPayload: null,
  regionsByOrchestratorType: null,
  selectedOptionalComponents: [],
};

const deploymentReducer = (state: DefaultStateI = initialState, action: DeploymentDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case CDS_ENVIRONMENT:
      return {
        ...state,
        cdsEnvironment: action.payload,
      };
    case SELECTED_CAPABILITY_INDEX: {
      return {
        ...state,
        selectedCapabilityIndex: action.payload,
      };
    }
    case SELECTED_CAPABILITY_KEY: {
      return {
        ...state,
        selectedCapabilityKey: action.payload,
      };
    }
    case SHOW_ALL_L03S: {
      return {
        ...state,
        showAllL03s: action.payload,
      };
    }
    case DEPLOYMENT_DEFINITION: {
      return {
        ...state,
        deploymentDefinition: action.payload,
      };
    }
    case CI_REGION_PAYLOAD: {
      return {
        ...state,
        ciRegionPayload: action.payload,
      };
    }
    case REGION_BY_ORCHESTRATOR_TYPE: {
      return {
        ...state,
        regionsByOrchestratorType: action.payload,
      };
    }
    case SELECTED_OPTIONAL_COMPONENT: {
      return {
        ...state,
        selectedOptionalComponents: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default deploymentReducer;
