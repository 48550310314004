import React, { useEffect, FC, ReactElement } from 'react';

import { PrimaryButton } from '@fluentui/react';

import TitleDescription from '../../../components/title-description/TitleDescription';
import InstallationStateContainer from '../../../stateContainers/installationStateContainer';
import ProgressService from '../../../services/progressService';
import PubSub from '../../../pubsub';
import { telemetry } from '../../../services/telemetryService';
import AuthenticationStateContainer from '../../../stateContainers/authenticationStateContainer';
import config, { telemetryConstants } from '../../../config';
import styles from './installation.styles';
import { commonStyles } from '../commonStyles.styles';
import Survey from '../../../components/survey/survey';
import CesEligibility from '../../../services/cesService';
import UserName from '../../../common/fetch-Information/UserName';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import * as Enum from '../../../common/Enum';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { DeploymentFailedMessageBar } from '../../../components/statusBars/deploymentFailedMessageBar';
import { ProgressMessageBar } from './deploymentProgressContainer/progressMessageBar/progressMessageBar';
import DeploymentProgressContainer from './deploymentProgressContainer';
import * as Util from '../../../utils/helper';

export interface InstallationProps {
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  intl: IntlShape;
}

export const Installation: FC<InstallationProps> = (props: InstallationProps): ReactElement => {
  var progressCheckIntervalId: any = null;
  var hasEligibilityChecked: boolean = false;

  const [deploymentProgress, setDeploymentProgress] = React.useState<ApiType.DeploymentStatusResponse>();
  const [isDeploymentFailed, setIsDeploymentFailed] = React.useState<boolean>(false);
  const [isSurveyEligible, setIsSurveyEligible] = React.useState<boolean>(false);
  const [failedDeploymentCorrelationId, setFailedDeploymentCorrelationId] = React.useState<string>('');
  const location = useLocation();

  const setSurvey = async () => {
    if (!hasEligibilityChecked) {
      var eligibility = await CesEligibility.getEligibility(config.pages.Deploy);

      telemetry.logTrace(
        'CES display survey eligibility for the Deploy Page returned as: ' + eligibility,
        telemetryConstants.severity.SEVERITY_INFO
      );

      if (eligibility) {
        setTimeout(() => setIsSurveyEligible(eligibility), config.ces.surveyTimerInSeconds * 1000);
      }

      hasEligibilityChecked = true;
    }
  };

  const checkProgress = async () => {
    setIsSurveyEligible(false);
    const deploymentId = InstallationStateContainer.getDeploymentId();
    try {
      if (deploymentId) {
        const progress = await ProgressService.getInstallationProgress(deploymentId);
        if (progress) {
          const deploymentFailed = progress.deploymentStatus === Enum.DeploymentStatus[Enum.DeploymentStatus.Failure];

          setDeploymentProgress(progress);
          setIsDeploymentFailed(deploymentFailed);

          if (deploymentFailed) {
            setFailedDeploymentCorrelationId(progress?.correlationId);
            telemetry.logTrace(
              telemetryConstants.installation.INSTALLATION_TELEMETRY_DEPLOY_SOLUTION_FAILED,
              telemetryConstants.severity.SEVERITY_ERROR
            );
          }
        }

        const deploymentCompleted =
          progress?.deploymentStatus === Enum.DeploymentStatus[Enum.DeploymentStatus.Completed];
        if (deploymentCompleted) {
          showSuccess();
        }
        return !!deploymentCompleted;
      } else {
        telemetry.logTrace('Unable to retrieve deployment ID from session', telemetryConstants.severity.SEVERITY_ERROR);
      }
    } catch (error) {
      telemetry.logException(error);
      return false; // Resolve promise with false so a retry will occur
    }
  };

  const resolveIn60Seconds = () => {
    return new Promise<void>((resolve) => {
      setTimeout(() => resolve(), 60000);
    });
  };

  const runAfterProgressResolvedAndAfter60Seconds = async () => {
    // This will resolve when both 5 seconds have passed AND when the progress request is back
    await Promise.all([resolveIn60Seconds(), checkProgress()]).then(([_, isProgressCompleted]) => {
      if (!isProgressCompleted) {
        return runAfterProgressResolvedAndAfter60Seconds();
      }
    });
  };

  useEffect(() => {
    var metricName =
      telemetryConstants.metrics.TRACKING_TYPE_PAGE_VISIT_DURATION +
      ': ' +
      telemetryConstants.installation.INSTALLATION_TELEMETRY_DEPLOY_SOLUTION_VALUE;
    telemetry.logPageView(telemetryConstants.installation.INSTALLATION_TELEMETRY_DEPLOY_SOLUTION_VALUE);
    telemetry.startMetric(metricName);

    runAfterProgressResolvedAndAfter60Seconds();
    setSurvey();

    return () => {
      var metricName =
        telemetryConstants.metrics.TRACKING_TYPE_PAGE_VISIT_DURATION +
        ': ' +
        telemetryConstants.installation.INSTALLATION_TELEMETRY_DEPLOY_SOLUTION_VALUE;

      clearInterval(progressCheckIntervalId);
      telemetry.stopMetric(metricName);
    };
  }, []);

  useEffect(() => {
    document.querySelector('h1')?.scrollIntoView();
  }, [location.pathname]);

  const showSuccess = () => {
    PubSub.publish('installationSuccess');
  };

  const { intl } = props;

  let topTitle = intl.formatMessage({ id: 'installStepper.deploySolution' });
  let topDescription = intl.formatMessage({
    id: 'installStepper.installationDescription',
  });

  return (
    <>
      {isDeploymentFailed && <ProgressMessageBar correlationId={failedDeploymentCorrelationId} />}
      <div className={commonStyles.mainContainer}>
        {isSurveyEligible && (
          <Survey
            userId={AuthenticationStateContainer.getUserId()}
            tenantId={AuthenticationStateContainer.getTenantId()}
            correlationId={AuthenticationStateContainer.getCorrelationId()}
            userName={UserName.getUserName()}
          />
        )}

        <div id="loading.animation.container" className={styles.installationTitleContainer}>
          <TitleDescription title={topTitle} description={topDescription} titleClassName={styles.stepTitle} />
        </div>

        {deploymentProgress ? (
          <div className={styles.progressContainer}>
            <DeploymentProgressContainer
              success={!isDeploymentFailed}
              deploymentProgressStatus={Util.filterSampleDataDeploymentProgressStatus(
                Object.values(deploymentProgress.deploymentDetails)
              )}
            />
          </div>
        ) : isDeploymentFailed ? (
          <DeploymentFailedMessageBar correlationId={failedDeploymentCorrelationId} />
        ) : (
          <>
            <div className={styles.loadingContainer}>
              <div className={styles.loadingContainerImage}>
                <img className={styles.loadingImage} src="./assets/images/loading_animation.gif" alt="" />
                <div className={styles.loadingImageLabel}>
                  <span>
                    <FormattedMessage id="installStepper.loadingLabel" />
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className={commonStyles.footerContainer}>
        <PrimaryButton
          id="close.primary.button"
          text={intl.formatMessage({ id: 'buttons.close' })}
          onClick={() =>
            props.history.push(config.routes.home, {
              from: props.location.pathname,
            })
          }
          allowDisabledFocus
        />
      </div>
    </>
  );
};

export default injectIntl(Installation);
