import React from 'react';
import { telemetry } from '../../services/telemetryService';
import config, { telemetryConstants, availableLanguages, surveyAvailableLanguages } from '../../config';
import surveyStyles from './survey.styles';
import { injectIntl } from 'react-intl';
import { ResponsiveModeContext } from '../../contexts/ResponsiveModeContext';
import { ResponsiveMode } from '../../common/responsiveMode';

class Survey extends React.Component {
  static contextType = ResponsiveModeContext;
  constructor(props) {
    super(props);
    const locale = this.props.intl.locale;
    const userSurveyLocale = surveyAvailableLanguages[availableLanguages.findIndex((loc) => loc === locale)];
    this.state = { userSurveyLocale };
  }

  renderSurvey = () => {
    const surveyEmbed = window.SurveyEmbed;
    const context = {
      UserName: this.props.userName,
      UserId: this.props.userId,
      TenantId: this.props.tenantId,
      CorrelationId: this.props.correlationId,
      Locale: this.state.userSurveyLocale,
    };

    try {
      const se = new surveyEmbed(
        config.ces.FormId,
        config.ces.CustomerVoiceUrl,
        config.ces.SurveyEmbedUrl,
        config.ces.SurveyDisplay
      );

      telemetry.logTrace(
        'CES survey render popup invoked for Locale ' +
          this.state.userSurveyLocale +
          ' for user ' +
          this.props.userName,
        telemetryConstants.severity.SEVERITY_INFO
      );

      if (this.context === ResponsiveMode.Desktop) {
        se.renderPopup(context, surveyStyles.surveyContainer.width, surveyStyles.surveyContainer.height);
      } else {
        se.renderPopup(context, surveyStyles.surveyContainerZoom.width, surveyStyles.surveyContainerZoom.height);
      }
      telemetry.logTrace(
        'Successfully showed CES Survey Form to the user' + config.ces.FormId,
        telemetryConstants.severity.SEVERITY_INFO
      );
    } catch (ex) {
      telemetry.logException(ex);
      telemetry.logTrace(
        'Exception rendering CES survey pop-up form: ' + ex,
        telemetryConstants.severity.SEVERITY_ERROR
      );
    }
  };

  render() {
    return (
      <div>
        <script type="text/javascript">{this.renderSurvey()}</script>
      </div>
    );
  }
}

export default injectIntl(Survey);
