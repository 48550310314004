import React, { useState, useEffect } from 'react';
import { Stack, Separator, Spinner, TooltipHost } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';
import { numericalSpacingStackTokens, styles, tooltipContent } from './learningHub.styles';
import LearningCard from '../../../components/cards/learningCard/learningCard';
import SetupGuideBlock from '../../../components/cards/setupGuideBlock/setupGuideBlock';
import LearningsService from '../../../services/learningsService';
import SetupGuideService from '../../../services/setupGuideService';

const fallbackIconUrl = '/assets/icons/Favicon_32px.png';

export const LearningHub = () => {
  const [learnings, setLearnings] = useState([]);
  const [setupGuides, setSetupGuides] = useState([]);
  const [setupGuidesLoading, setSetupGuidesLoading] = useState(true);
  const [learningsLoading, setLearningsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await fetchLearningsData();
      await fetchSetupGuidesData();
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setSetupGuidesLoading(false);
      setLearningsLoading(false);
    }
  };

  const fetchLearningsData = async () => {
    try {
      const allLearnings = await LearningsService.getAllLearnings();
      setLearnings(allLearnings || []);
    } catch (error) {
      console.error('Error fetching learnings:', error);
    }
  };

  const fetchSetupGuidesData = async () => {
    try {
      const allSetupGuides = await SetupGuideService.getAllSetupGuides();
      setSetupGuides(allSetupGuides || []);
    } catch (error) {
      console.error('Error fetching setup guides:', error);
    }
  };

  return (
    <Stack className={styles.page} tokens={numericalSpacingStackTokens}>
      <Stack>
        <p className={styles.pageTitle}>
          <FormattedMessage id="learningHub.pageTitle" />
        </p>
        <p className={styles.pageDescription}>
          <FormattedMessage id="learningHub.pageTitle.description" />
        </p>
      </Stack>
      <Separator />
      <Stack>
        <div className={styles.setupGuideHeading}>
          <FormattedMessage id="learningHub.setupGuide" />
        </div>
        <div className={styles.setupGuideDescription}>
          <FormattedMessage id="learningHub.setupGuide.description" />
        </div>
        <div className={styles.scrollableSection}>
          {setupGuidesLoading ? (
            <div className={styles.spinnerContainer}>
              <Spinner label="Loading..." />
            </div>
          ) : (
            <div className={styles.scrollableContent}>
              {setupGuides.map((guide, index) => (
                <TooltipHost
                  key={index}
                  content={guide.description}
                  id={`setupGuideTooltip-${index}`}
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={5}
                >
                  <SetupGuideBlock
                    title={guide.title}
                    description={guide.description}
                    thumbnailLink={guide.thumbnailLink}
                    videoLink={guide.videoLink}
                  />
                </TooltipHost>
              ))}
            </div>
          )}
        </div>
      </Stack>
      <Separator />
      <Stack>
        <p className={styles.industryCloudDocumentationHeading}>
          <FormattedMessage id="learningHub.industryCloudDocumentation" />
        </p>
        <p className={styles.industryCloudDocumentationHeadingDescription}>
          <FormattedMessage id="learningHub.industryCloudDocumentation.description" />
        </p>
        <div className={styles.card}>
          {learningsLoading ? (
            <div className={styles.spinnerContainerLearnings}>
              <Spinner label="Loading..." />
            </div>
          ) : (
            learnings.map((learning, index) => {
              const iconUrl = `/assets/icons/${learning.title}_icon.png`;
              return (
                <TooltipHost
                  key={index}
                  content={learning.description.length > 170 ? `${learning.description}` : learning.description}
                  id={`learningTooltip-${index}`}
                  calloutProps={{ gapSpace: 0 }}
                  directionalHint={5}
                  tooltipProps={{
                    styles: {
                      content: tooltipContent,
                    },
                  }}
                >
                  <LearningCard
                    title={learning.title}
                    description={
                      learning.description.length > 170
                        ? `${learning.description.slice(0, 172)}...`
                        : learning.description
                    }
                    url={learning.learnLink}
                    icon={iconUrl || fallbackIconUrl}
                  />
                </TooltipHost>
              );
            })
          )}
        </div>
      </Stack>
    </Stack>
  );
};
export default LearningHub;
