const surveyStyles = {
  surveyContainer: {
    height: '550px',
    width: '1000px',
  },
  surveyContainerZoom: {
    height: '250px',
    width: '500px',
  },
};
export default surveyStyles;
