import React, { FC, ReactElement } from 'react';
import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import { inlineStyles } from '../progressStyling/index.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomSupportPanel } from '../../../../../components/customSupportPanel/customSupportPanel';
import { useBoolean } from '@fluentui/react-hooks';
import SolutionsStateContainer from '../../../../../stateContainers/solutionsStateContainer';

export interface ProgressMessageBarProps {
  correlationId: string;
}

export const ProgressMessageBar: FC<ProgressMessageBarProps> = (props: ProgressMessageBarProps): ReactElement => {
  const intl = useIntl();

  const [isSupportPanelOpen, { setTrue: openSupportPanel, setFalse: closeSupportPanel }] = useBoolean(false);

  return (
    <>
      <MessageBar
        id="progress.message.bar"
        messageBarIconProps={inlineStyles}
        overflowButtonAriaLabel={intl.formatMessage({ id: 'buttons.close' })}
        dismissButtonAriaLabel={intl.formatMessage({ id: 'buttons.close' })}
        truncated={true}
        messageBarType={MessageBarType.error}
        actions={
          <div>
            <MessageBarButton onClick={openSupportPanel}>
              {intl.formatMessage({ id: 'installStepper.ContactSupport' })}
            </MessageBarButton>
          </div>
        }
      >
        {' '}
        {<FormattedMessage id={'installStepper.installationError2'} />} {props.correlationId}
      </MessageBar>
      <CustomSupportPanel
        isOpen={isSupportPanelOpen}
        onDismiss={closeSupportPanel}
        selectedL01RowKey={SolutionsStateContainer.getSelectedSolution()}
      />
    </>
  );
};
