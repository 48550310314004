export enum DeploymentStatus {
  NotStarted = 0,

  UpdateAvailable = 1,

  InProgress = 2,

  Failure = 3,

  Success = 4,

  Completed = 5,
}

export enum HTTPMethod {
  GET = 'GET',

  POST = 'POST',

  PUT = 'PUT',
}

export enum PackageType {
  ARM_PACKAGE = 'ARM_PACKAGE',

  CI_PACKAGE = 'CI_PACKAGE',

  DATAVERSE_PACKAGE = 'DATAVERSE_PACKAGE',

  PBI_PACKAGE = 'PBI_PACKAGE',
}

export enum OrchestratorType {
  ARM = 'azure',

  DATAVERSE = 'dataverse',

  APPSOURCE = 'appsource',

  CUSTOMERINSIGHTS = 'customerinsights',

  POWERBI = 'powerbi',
}

export enum MultiStepperPackageType {
  PowerPlatform = 'Power Platform',
  CustomerInsights = 'Customer Insights',
}

export enum ARMDeploymentStatus {
  Succeeded = 'Succeeded',
}

export enum ArmValidationType {
  Validate,
  WhatIf,
}

export enum PermissionState {
  PROMPT = 'PROMPT',
  GRANTED = 'GRANTED',
  DENIED = 'DENIED',
}

export enum TrialProvisionType {
  PORTAL = 'Portal',
  TRIAL = 'Trial',
  NONTRIAL = 'NonTrial',
}

export enum BapEnvProvisioningState {
  NotSpecified = 'NotSpecified',
  Creating = 'Creating',
  Succeeded = 'Succeeded',
  Deleting = 'Deleting',
  Deleted = 'Deleted',
  FailedDeleting = 'FailedDeleting',
  LinkedDatabaseProvisioning = 'LinkedDatabaseProvisioning',
  LinkedDatabaseFailedProvisioning = 'LinkedDatabaseFailedProvisioning',
  LinkedDatabaseUnavailable = 'LinkedDatabaseUnavailable',
  Updating = 'Updating',
}

export enum SortOptionsL03s {
  SortDefault = 'default',
  SortAscending = 'ascending',
  SortDescending = 'descending',
  SortNewest = 'newest',
  SortOldest = 'oldest',
}

export enum ReleaseType {
  GA = 'GA',
  Preview = 'Preview',
}

export enum ListViewColumnFieldNames {
  Solutions = 'Solutions',
  BusinessNeed = 'BusinessNeed',
  ReleaseType = 'ReleaseType',
  Requirements = 'Requirements',
  Actions = 'Actions',
  DeploymentName = 'DeploymentName',
  EnvironmentName = 'EnvironmentName',
  LastModified = 'LastModified',
  Status = 'Status',
}

export enum PackageDeploymentType {
  TPS = 'TPS',
  CI = 'CI',
  ARM = 'ARM',
  PBITemplateApp = 'PBITemplateApp',
}
