import React from 'react';
import { useIntl } from 'react-intl';
import styles from './destination.styles';
import { Text } from '@fluentui/react';
import { PackageType } from '../../../common/Enum';
import { deploymentTypeDisplayStrings } from '../../../common/Constants';

export function StepText({ stepNumber, solutionNames, deploymentType }) {
  const intl = useIntl();
  const commaSeparatedSolutionNames = solutionNames?.join(', ') ?? '';
  let title;
  if (deploymentType === PackageType.ARM_PACKAGE) {
    title = intl.formatMessage(
      {
        id: 'destination.azureHeaderTitle',
      },
      {
        stepNumber: stepNumber,
        deploymentType: deploymentTypeDisplayStrings.AZURE,
        visibleSolutions: commaSeparatedSolutionNames,
      }
    );
  } else {
    title = intl.formatMessage(
      {
        id: 'destination.headerTitle',
      },
      {
        stepNumber: stepNumber,
        deploymentType: deploymentType,
        visibleSolutions: commaSeparatedSolutionNames,
      }
    );
  }
  return (
    <div>
      <h2>
        <div role="alert" aria-label={title} className={styles.destinationTitleContainer}>
          <Text variant="small" className={styles.destinationTitleStyles}>
            {title}
          </Text>
        </div>
      </h2>
    </div>
  );
}
