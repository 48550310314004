import RestService from './restService';
import config from '../config';
import * as ApiType from '../solutionCenterApi/gen';
import { ArmValidationType, HTTPMethod } from '../common/Enum';
import axios from 'axios';
import {
  IAzureResourceGroup,
  IAzureSubscription,
} from '../pages/install/destination/azureResourceWidget/models/azureResource.Models';
import { telemetry } from './telemetryService';
import * as Type from '../common/Type';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ArmDeploymentScope } from '../solutionCenterApi/gen';
import { deploymentProperties } from '../common/Type';
import { TypeParameterKey } from '../solutionCenterApi/gen';

const configs = config.azureEndpoints;
export class AzureManagementService {
  public static async getL03ParameterDefinitionList(l03RowKey: string): Promise<ApiType.ParameterDefinition[] | null> {
    telemetry.logTrace(`Request data from /additionalDeploymentParameters`);
    let result = await RestService.get({
      endPoint: config.endpoints.getL03ParameterDefinitions + l03RowKey + '/additionalDeploymentParameters',
    });
    telemetry.logTrace(`Response from /additionalDeploymentParameters: ` + result);
    return result;
  }

  public static async getOCParameterDefinitionList(
    optionalComponentRowKey: string
  ): Promise<ApiType.ParameterDefinition[] | null> {
    let result = await RestService.get({
      endPoint:
        config.endpoints.getOCParameterDefinitions + optionalComponentRowKey + '/additionalDeploymentParameters',
    });

    return result;
  }

  public static async getL03OrOCDeploymentScope(rowKey: string, entityType: string): Promise<string> {
    telemetry.logTrace(`Request data from /AzureDeployments/L03/` + rowKey + `/deployment-scope`);
    let result = await RestService.get({
      endPoint: config.endpoints.azureDeploymentsPath + entityType + '/' + rowKey + config.endpoints.deploymentScope,
    });

    telemetry.logTrace(`Response from /deployment-scope: ` + result);
    return result;
  }

  public static async getExistingDeploymentForAzure(
    subscriptionId: string,
    resourceGroupName: string,
    l01RowKey: string | null
  ): Promise<ApiType.DeploymentDefinitionEntity | null> {
    let endPointUrl = config.endpoints.getExistingDeploymentForAzure;
    if (subscriptionId && resourceGroupName && l01RowKey) {
      telemetry.logTrace(`Requesting data from /getExistingDeploymentForAzureAsync`);
      endPointUrl = endPointUrl
        .replace('{subscriptionId}', subscriptionId)
        .replace('{resourceGroupName}', resourceGroupName)
        .replace('{l01RowKey}', l01RowKey);
      let result = await RestService.get({
        endPoint: endPointUrl,
      });
      telemetry.logTrace(`Response from /getExistingDeploymentForAzureAsync: ${result}`);
      return result;
    } else {
      telemetry.logException('/getExistingDeploymentForAzureAsync call returned response null');
      return null;
    }
  }

  public static async getExistingDeploymentForAzureTenantScope(
    l01RowKey: string | null
  ): Promise<ApiType.DeploymentDefinitionEntity | null> {
    let endPointUrl = config.endpoints.getExistingDeploymentForAzureTenantScope;
    if (l01RowKey) {
      telemetry.logTrace(`Requesting data from /tenant-scope/l01/{l01RowKey}`);
      endPointUrl = endPointUrl.replace('{l01RowKey}', l01RowKey);
      let result = await RestService.get({
        endPoint: endPointUrl,
      });
      telemetry.logTrace(`Response from /getExistingDeploymentForAzureTenantScope: ${result}`);
      return result;
    } else {
      telemetry.logException('/getExistingDeploymentForAzureTenantScope call returned response null');
      return null;
    }
  }

  public static async getSubscriptionList(
    azureToken: string
  ): Promise<IAzureSubscription[] | IAzureResourceGroup[] | string | undefined> {
    let response: any;
    const url = `${configs.azureResourceManagementApiEndpoint}${configs.subscriptions}?api-version=${configs.azureResourceManagementApiVersion}`;
    const method = HTTPMethod.GET;
    const subscriptionConfig = { url, method };
    response = await this.getAzureManagementServiceList(azureToken, subscriptionConfig, 'getSubscriptionsList');
    return response ? response.value : response;
  }

  public static async getResourceGroupList(
    azureToken: string,
    subscriptionId: string
  ): Promise<IAzureSubscription[] | IAzureResourceGroup[] | string | undefined> {
    let response: any;
    const url = `${configs.azureResourceManagementApiEndpoint}${configs.subscriptions}/${subscriptionId}${configs.resourcegroups}?api-version=${configs.azureResourceManagementApiVersion}`;
    const method = HTTPMethod.GET;
    const subscriptionConfig = { url, method };
    response = await this.getAzureManagementServiceList(azureToken, subscriptionConfig, 'getResourceGroupsList');
    return response ? response.value : response;
  }

  public static async getLocationsList(
    azureToken: string,
    subscriptionId: string
  ): Promise<IAzureSubscription[] | IAzureResourceGroup[] | string | undefined> {
    let response: any;
    const url = `${configs.azureResourceManagementApiEndpoint}${configs.subscriptions}/${subscriptionId}${configs.locations}?api-version=${configs.azureResourceManagementApiVersion}`;
    const method = HTTPMethod.GET;
    const subscriptionConfig = { url, method };
    response = await this.getAzureManagementServiceList(azureToken, subscriptionConfig, 'getLocationsList');
    return response ? response.value : response;
  }

  public static async createNewResourceGroup(
    azureToken: string | undefined,
    subscriptionId: string | undefined,
    resourceGroupName: string | undefined,
    data: Type.CreateResourceGroup | string
  ): Promise<IAzureSubscription[] | IAzureResourceGroup[] | IAzureResourceGroup | string> {
    const url = `${configs.azureResourceManagementApiEndpoint}${configs.subscriptions}/${subscriptionId}${configs.resourcegroups}/${resourceGroupName}?api-version=${configs.azureResourceManagementApiVersion}`;
    const method = HTTPMethod.PUT;
    const createRGConfig = { url, method, data };
    return await this.getAzureManagementServiceList(azureToken, createRGConfig, 'createNewResourceGroups');
  }

  public static async permissionsAuthorizationCheck(
    azureToken: string,
    subscriptionId: string
  ): Promise<Type.permissionsResponse[] | undefined> {
    let response: any;
    const url = `${configs.azureResourceManagementApiEndpoint}${configs.subscriptions}/${subscriptionId}/${configs.permissions}?api-version=${configs.azureResourceManagementRBACApiVersion}`;
    const method = HTTPMethod.GET;
    const subscriptionConfig = { url, method };
    response = await this.getAzureManagementServiceList(
      azureToken,
      subscriptionConfig,
      'permissionsAuthorizationCheck'
    );
    return response ? response.value : response;
  }

  public static async validateDeployments(
    azureToken: string,
    data: Type.ARMDeploymentProperties,
    deploymentName: string,
    armDeploymentScope: ArmDeploymentScope,
    subscriptionId?: string,
    resourceGroupName?: string
  ): Promise<string | undefined | Type.cloudErrorResponse> {
    let url: string;
    if (armDeploymentScope === ArmDeploymentScope.RESOURCE_GROUP) {
      url = `${configs.azureResourceManagementApiEndpoint}${configs.subscriptions}/${subscriptionId}${configs.resourcegroups}/${resourceGroupName}${configs.microsoftResourceProviders}${configs.deployments}/${deploymentName}${configs.validate}?api-version=${configs.azureResourceManagementApiVersion}`;
    } else {
      url = `${configs.azureResourceManagementApiEndpoint}${configs.microsoftResourceProviders}${configs.deployments}/${deploymentName}${configs.validate}?api-version=${configs.azureResourceManagementApiVersion_202104}`;
    }
    let headers;
    if (azureToken) {
      headers = {
        Authorization: 'Bearer ' + azureToken,
      };
    }
    let validateAzureDeployments = null;
    telemetry.logTrace(
      `validateDeployments call initiated`,
      SeverityLevel.Information,
      `
            armDeploymentScope: ${armDeploymentScope},
            subscriptionId:  ${subscriptionId},
            resourceGroupName: ${resourceGroupName},
            deploymentName: ${deploymentName}`
    );

    await axios
      .post(url, data, { headers })
      .then((response) => {
        validateAzureDeployments = response;
      })
      .catch((error) => {
        validateAzureDeployments = error.response.data.error;
        telemetry.logTrace(`validateDeployments response`, SeverityLevel.Information, validateAzureDeployments);
      });

    telemetry.logTrace(`validateDeployments response received`, SeverityLevel.Information);
    return validateAzureDeployments;
  }

  public static async validateAzureRequest(
    validationType: ArmValidationType,
    location: string,
    deploymentName: string,
    properties: deploymentProperties,
    deploymentScope?: ArmDeploymentScope | undefined,
    typeParameters?: { [key in keyof typeof TypeParameterKey]?: string } | null | undefined
  ): Promise<string | undefined> {
    let validateAzureDeployments = null;
    telemetry.logTrace(
      `validateAzureRequest SC API call initiated`,
      SeverityLevel.Information,
      `deploymentName: ${deploymentName}`
    );
    let url = config.endpoints.azureDeploymentsPath + config.endpoints.azureValidatePath;
    if (validationType === ArmValidationType.WhatIf) {
      url = config.endpoints.azureDeploymentsPath + config.endpoints.azureWhatIfPath;
    }
    try {
      validateAzureDeployments = await RestService.post({
        endPoint: url,
        data: {
          location: location,
          offerId: properties.templateLink.uri,
          deploymentName: deploymentName,
          deploymentScope: deploymentScope,
          typeParameters: typeParameters,
          properties: properties,
        },
      });
      telemetry.logTrace(`validateAzureRequest SC API response received`, SeverityLevel.Information);
    } catch (error) {
      telemetry.logTrace(`validateAzureRequest SC API error response`, SeverityLevel.Information, error);
    }
    return validateAzureDeployments;
  }

  public static async whatIfDeployment(
    azureToken: string,
    data: Type.ARMDeploymentProperties,
    deploymentName: string,
    armDeploymentScope: ArmDeploymentScope,
    subscriptionId?: string,
    resourceGroupName?: string
  ): Promise<string | undefined> {
    let url = '';
    if (armDeploymentScope === ArmDeploymentScope.RESOURCE_GROUP) {
      url = `${configs.azureResourceManagementApiEndpoint}${configs.subscriptions}/${subscriptionId}${configs.resourcegroups}/${resourceGroupName}${configs.microsoftResourceProviders}${configs.deployments}/${deploymentName}${configs.whatIf}?api-version=${configs.azureResourceManagementApiVersion}`;
    } else {
      url = `${configs.azureResourceManagementApiEndpoint}${configs.microsoftResourceProviders}${configs.deployments}/${deploymentName}${configs.whatIf}?api-version=${configs.azureResourceManagementApiVersion_202104}`;
    }
    let headers;
    if (azureToken) {
      headers = {
        Authorization: 'Bearer ' + azureToken,
      };
    }
    let validateAzureDeployments;
    telemetry.logTrace(
      `whatIfDeployment call initiated`,
      SeverityLevel.Information,
      `
            armDeploymentScope: ${armDeploymentScope},
            subscriptionId: ${subscriptionId},
            resourceGroupName: ${resourceGroupName},
            deploymentName: ${deploymentName}`
    );

    await axios
      .post(url, data, { headers })
      .then((response) => {
        validateAzureDeployments = response;
      })
      .catch((error) => {
        validateAzureDeployments = error.response.data.error;
        telemetry.logTrace(`whatIfDeployment response`, SeverityLevel.Information, validateAzureDeployments);
      });
    telemetry.logTrace(`whatIfDeployment response received`, SeverityLevel.Information);

    return validateAzureDeployments;
  }

  public static async checkLongRunningProcess(
    azureToken: string,
    location: string
  ): Promise<IAzureSubscription[] | IAzureResourceGroup[] | string | undefined> {
    let response: any;
    const url = location;
    const method = HTTPMethod.GET;
    const checkLongRunningProcessConfig = { url, method };
    response = await this.getAzureManagementServiceList(
      azureToken,
      checkLongRunningProcessConfig,
      'checkLongRunningProcess'
    );
    return response;
  }

  private static async getAzureManagementServiceList(
    azureToken: string,
    requestConfig: any,
    telemetryString: string
  ): Promise<IAzureSubscription[] | IAzureResourceGroup[] | IAzureResourceGroup | string | undefined> {
    await RestService.refreshTokenIfCloseToExpire();
    if (azureToken) {
      const headers = {
        Authorization: 'Bearer ' + azureToken,
      };
      const config = {
        ...requestConfig,
        headers: headers,
      };

      try {
        const response = await axios.request(config);
        if (response.status !== 200 && response.status !== 201) {
          telemetry.logException(`${telemetryString} call returned: ${response.status}`);
          return response.data;
        }
        telemetry.logTrace(`Request to ${telemetryString} for URL ${requestConfig.queryUrl} was successful`);
        if (!response.data) {
          telemetry.logException(`${telemetryString}  call returned response: ${response.data}`);
          return;
        }
        return response.data;
      } catch (error) {
        telemetry.logException(`${telemetryString} call returned: ${error}`);
        telemetry.logException(error);
        return;
      }
    }
  }
}
