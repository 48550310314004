import RestService from './restService';
import config, { telemetryConstants } from '../config';
import DeploymentStateContainer from '../stateContainers/deploymentStateContainer';
import * as Type from '../common/Type';
import * as Util from '../utils/helper';
import * as ApiType from '../solutionCenterApi/gen';
import { telemetry } from './telemetryService';
import * as Constants from '../common/Constants';
import { ARMPackageValidator, CIPackageValidator, DataversePackageValidator } from '../models/packageValidator';
import { PackageType } from '../common/Enum';
import { ArmDeploymentScope } from '../solutionCenterApi/gen';
import SolutionsStateContainer from '../stateContainers/solutionsStateContainer';
import EnvironmentService from './environmentsService';
import Utils from '../utils';

const DeployService = {
  deploy: async (
    selectedOffers: ApiType.L03[],
    environment: Type.Environment,
    installSampleData: boolean,
    installAdditionalSampleData: boolean,
    cIEnvironment: ApiType.InstanceMetadata,
    solutionRowKey: string | null,
    azureDeploymentScopes: { [key in keyof typeof ArmDeploymentScope]?: string[] } | undefined,
    azureDeployInstance: Type.AzureDeployableInstance | null,
    pbiPayload: ApiType.PBIPayload | undefined,
    instanceName: string | null,
    selectedOptionalComponents?: ApiType.OptionalComponent[]
  ): Promise<ApiType.DeploymentResponse | undefined> => {
    var customProps: any = {};
    let isPackageValidated: boolean;
    let isOptionalComponentPackagesValidated: boolean = false;
    let existingDeployments = null;
    const data: ApiType.DeploymentPayloadV2 = {
      environmentId: environment?.environmentId,
      deploymentName: DeploymentStateContainer.getDeploymentName() ?? '',
      instanceApiUrl: environment?.apiUrl,
      instanceId: environment?.id,
      instanceName:
        !environment?.friendlyName && !azureDeployInstance && pbiPayload?.l03RowKeys?.length! > 0
          ? instanceName!
          : environment?.friendlyName,
      instanceType: environment?.type,
      installSampleData: false,
      installAdditionalSampleData: false,
      industryVerticalKeys: SolutionsStateContainer.getSelectedIndustryVerticalKeys(),
      l03RowKeys: [],
      ciInstanceId: cIEnvironment?.instanceId,
      ciInstanceName: cIEnvironment?.name,
      l01RowKey: solutionRowKey ?? '',
      armScopeToOffersDict: azureDeploymentScopes,
      azureDeploymentRegion: azureDeployInstance?.azureRegion?.name,
      azureSubscriptionId: azureDeployInstance?.subscription?.subscriptionId,
      azureSubscriptionName: azureDeployInstance?.subscription?.displayName,
      azureResourceGroupName: azureDeployInstance?.resourceGroup?.name,
      additionalDeploymentParameters: azureDeployInstance?.additionalDeploymentParametersMap,
      includeAzureDeployment: azureDeployInstance ? true : false,
      pbiTemplateAppPayload: pbiPayload?.l03RowKeys ? pbiPayload : undefined,
      optionalComponentRowKeys:
        selectedOptionalComponents?.length !== 0
          ? Util.getOptionalComponentRowKeys(selectedOptionalComponents!)
          : undefined,
      installOptionalComponent: selectedOptionalComponents?.length !== 0 ? true : false,
    };
    if (!Utils.isNullOrUndefined(environment)) {
      existingDeployments = await EnvironmentService.getExistingDeployments(environment?.id, solutionRowKey);
    }

    for (const offer of selectedOffers) {
      if (offer.rowKey) {
        var isOfferRequired = false;
        if (!Utils.isNullOrUndefinedOrEmpty(existingDeployments)) {
          telemetry.logTrace('existingDeployments is present', telemetryConstants.severity.SEVERITY_INFO, customProps);
          if (existingDeployments.deployedL03s.includes(offer.rowKey) === false) {
            isOfferRequired = true;
            telemetry.logTrace(
              `existingDeployments does not share L03 rowkey: ${offer.rowKey} `,
              telemetryConstants.severity.SEVERITY_INFO,
              customProps
            );
          } else {
            telemetry.logTrace(
              `existingDeployments share L03 rowkey: ${offer.rowKey} `,
              telemetryConstants.severity.SEVERITY_INFO,
              customProps
            );
          }
        } else {
          isOfferRequired = true;
          telemetry.logTrace(
            'existingDeployments is not present',
            telemetryConstants.severity.SEVERITY_INFO,
            customProps
          );
        }
        if (!data.l03RowKeys?.includes(offer.rowKey) && offer.additionalDeploymentParametersAvailable) {
          isOfferRequired = true;
          telemetry.logTrace(
            `additionalDeploymentParametersAvailable is true for L03 rowkey: ${offer.rowKey}`,
            telemetryConstants.severity.SEVERITY_INFO,
            customProps
          );
        }

        if (isOfferRequired && !data.l03RowKeys?.includes(offer.rowKey)) {
          data.l03RowKeys?.push(offer?.rowKey);
        } else {
          telemetry.logTrace(
            'The L03 already exists in the payload' + offer.rowKey,
            telemetryConstants.severity.SEVERITY_INFO,
            customProps
          );
        }
      } else {
        telemetry.logTrace('The offer rowkey is empty', telemetryConstants.severity.SEVERITY_ERROR, customProps);
      }
    }

    if (data.l03RowKeys?.length === 0) {
      telemetry.logTrace(
        'All selected L03s are part of ongoing deployments',
        telemetryConstants.severity.SEVERITY_INFO,
        customProps
      );
      telemetry.logEvents(telemetryConstants.events.DEPLOYMENT_CALL_FAILED, customProps);
      return undefined;
    }

    isPackageValidated = packageValidationHelper(selectedOffers, data, azureDeployInstance);

    if (selectedOptionalComponents) {
      isOptionalComponentPackagesValidated = packageValidationHelper(
        selectedOptionalComponents,
        data,
        azureDeployInstance
      );
      if (isPackageValidated && isOptionalComponentPackagesValidated) {
        return await RestService.post({
          endPoint: config.endpoints.deployL01,
          data,
        });
      } else {
        telemetry.logEvents(telemetryConstants.events.DEPLOYMENT_CALL_FAILED, customProps);
        return undefined;
      }
    } else {
      if (isPackageValidated) {
        return await RestService.post({
          endPoint: config.endpoints.deployL01,
          data,
        });
      } else {
        telemetry.logEvents(telemetryConstants.events.DEPLOYMENT_CALL_FAILED, customProps);
        return undefined;
      }
    }
  },
  getDeploymentDefinitons: async (): Promise<ApiType.DeploymentDefinitionEntity[]> => {
    const result = await RestService.get({
      endPoint: config.endpoints.getDeploymentDefinitions,
    });
    if (result) {
      DeploymentStateContainer.setDeploymentDefinitions(result);
    }
    return result;
  },
  getDeploymentUpdates: async (
    deployments: Array<ApiType.DeploymentDefinitionEntity>
  ): Promise<ApiType.DeploymentDefinitionEntity[]> => {
    const result = await RestService.post({
      endPoint: config.endpoints.checkDeploymentUpdates,
      data: deployments,
    });

    if (result) {
      DeploymentStateContainer.setDeploymentDefinitions(result);
    }
    return result;
  },
  getDeploymentDetails: async (deploymentContainerRowKey: string): Promise<ApiType.DeploymentDefinitionEntity> => {
    const result = await RestService.get({
      endPoint: config.endpoints.getDeploymentDetails + deploymentContainerRowKey,
    });
    if (result) {
      DeploymentStateContainer.setDeploymentDetails(result, deploymentContainerRowKey);
    }
    return result;
  },
  getUpdatesByDeploymentDefinitionRowKey: async (deploymentContainerRowKey: string): Promise<boolean> => {
    const result = await RestService.get({
      endPoint: config.endpoints.getDeploymentDetails + deploymentContainerRowKey + config.endpoints.checkUpdates,
    });
    return result;
  },
  putDeploymentName: async (
    deploymentDefinition: ApiType.DeploymentDefinitionEntity
  ): Promise<ApiType.DeploymentDefinitionEntity> => {
    const data: ApiType.DeploymentDefinitionEntity = {
      tenantId: deploymentDefinition.tenantId,
      name: deploymentDefinition.name,
      partitionKey: deploymentDefinition.partitionKey,
      rowKey: deploymentDefinition.rowKey,
      l01RowKey: deploymentDefinition.l01RowKey,
      timestamp: deploymentDefinition.timestamp,
      isModifiedByCurrentUser: deploymentDefinition.isModifiedByCurrentUser,
    };

    return await RestService.put({
      endPoint: config.endpoints.updateDeploymentName + deploymentDefinition.rowKey,
      data,
    });
  },
  updateL03: async (
    deploymentL03V2UpdatePayload: ApiType.DeploymentL03V2UpdatePayload
  ): Promise<ApiType.DeploymentResponse> => {
    const data = {
      deploymentL03V2RowKey: deploymentL03V2UpdatePayload.deploymentL03V2RowKey,
      l01RowKey: deploymentL03V2UpdatePayload.l01RowKey,
      environmentId: deploymentL03V2UpdatePayload.environmentId,
    };

    return await RestService.put({
      endPoint: config.endpoints.updateDeploymentL03 + deploymentL03V2UpdatePayload.deploymentL03V2RowKey + '/update',
      data,
    });
  },
  simpleUpdateDeploy: async (
    deploymentDefinitionRowKey: string,
    selectedOptionalComponents: ApiType.OptionalComponent[],
    deploymentPayload: ApiType.DeploymentPayloadV2
  ): Promise<ApiType.DeploymentResponse | undefined> => {
    var customProps: Record<string, string | ApiType.OptionalComponent[] | ApiType.DeploymentPayloadV2> = {};
    var isPackageValidated: boolean = false;
    customProps[Constants.TelemetryPropertyNames.deploymentDefinitionNameKey] = deploymentDefinitionRowKey;
    customProps[Constants.TelemetryPropertyNames.optionalComponentsKey] = selectedOptionalComponents;
    customProps[Constants.TelemetryPropertyNames.deploymentPayload] = deploymentPayload;
    if (deploymentDefinitionRowKey === undefined || deploymentDefinitionRowKey === null) {
      telemetry.logEvents(telemetryConstants.events.DEPLOYMENT_DEFINITION_ROWKEY_EMPTY, customProps);
    }

    isPackageValidated = packageValidationHelper(selectedOptionalComponents, deploymentPayload, null);

    if (isPackageValidated) {
      return await RestService.post({
        endPoint: config.endpoints.getDeploymentDetails + deploymentDefinitionRowKey,
        data: deploymentPayload,
      });
    } else {
      return undefined;
    }
  },
  complexUpdateDeploy: async (
    environmentId: string | undefined,
    deploymentDefinitionRowKey: string,
    deploymentDefinition: ApiType.DeploymentDefinitionEntity,
    selectedOptionalComponents: ApiType.OptionalComponent[],
    cIEnvironment: ApiType.InstanceMetadata,
    solutionRowKey: string | null,
    armDeploymentScopes: { [key in keyof typeof ArmDeploymentScope]?: string[] } | undefined,
    azureDeployInstance: Type.AzureDeployableInstance | null,
    instanceName: string | null,
    pbiPayload: ApiType.PBIPayload | undefined
  ): Promise<ApiType.DeploymentResponse | undefined> => {
    var customProps: Record<string, ApiType.DeploymentPayloadV2> = {};
    let isPackageValidated: boolean;
    const data: ApiType.DeploymentPayloadV2 = {
      environmentId: environmentId,
      deploymentName: DeploymentStateContainer.getDeploymentName() ?? '',
      instanceApiUrl: Util.findDataverseEnivronmentProperties(deploymentDefinition.l03s!, 'instanceApiUrl'),
      instanceId: Util.findDataverseEnivronmentProperties(deploymentDefinition.l03s!, 'instanceId')!,
      instanceName:
        !Util.findDataverseEnivronmentProperties(deploymentDefinition.l03s!, 'instanceName') && !azureDeployInstance
          ? instanceName!
          : Util.findEnvironmentBasedOnPackageType(
              deploymentDefinition.l03s!,
              ApiType.PackageType.Solution,
              'instanceName'
            ),
      instanceType: Util.findDataverseEnivronmentProperties(deploymentDefinition.l03s!, 'instanceType')!,
      installSampleData: false,
      installAdditionalSampleData: false,
      l03RowKeys: Util.getOptionalComponentRowKeys(selectedOptionalComponents),
      ciInstanceId: cIEnvironment?.instanceId,
      ciInstanceName: cIEnvironment?.name,
      l01RowKey: solutionRowKey ?? '',
      armScopeToOffersDict: armDeploymentScopes,
      azureDeploymentRegion: azureDeployInstance?.azureRegion?.name,
      azureSubscriptionId: azureDeployInstance?.subscription?.subscriptionId,
      azureSubscriptionName: azureDeployInstance?.subscription?.displayName,
      azureResourceGroupName: azureDeployInstance?.resourceGroup?.name,
      additionalDeploymentParameters: azureDeployInstance?.additionalDeploymentParametersMap,
      includeAzureDeployment: azureDeployInstance ? true : false,
      pbiTemplateAppPayload: pbiPayload ? pbiPayload : undefined,
      optionalComponentRowKeys: undefined,
      installOptionalComponent: false,
    };

    isPackageValidated = packageValidationHelper(selectedOptionalComponents, data, azureDeployInstance);
    customProps[Constants.TelemetryPropertyNames.deploymentPayload] = data;

    if (isPackageValidated) {
      return await RestService.post({
        endPoint: config.endpoints.getDeploymentDetails + deploymentDefinitionRowKey,
        data,
      });
    } else {
      telemetry.logEvents(telemetryConstants.events.DEPLOYMENT_CALL_FAILED, customProps);
      return undefined;
    }
  },
};

const packageValidationHelper = (
  selectedEntities: ApiType.L03[] | ApiType.OptionalComponent[],
  data: ApiType.DeploymentPayloadV2,
  azureDeployInstance: Type.AzureDeployableInstance | null
): boolean => {
  let isPackagesValidated: boolean;
  let validARMParams: Type.Result<Type.AzureDeployableInstance>;
  let validDataVerseParams: Type.Result<ApiType.DeploymentPayloadV2>;
  let validCIParams: Type.Result<ApiType.DeploymentPayloadV2>;
  var customProps: any = {};
  selectedEntities.forEach((l03: ApiType.L03 | ApiType.OptionalComponent) => {
    let dataverseSelection = l03.packages?.filter(
      (a: ApiType.Package) =>
        a.packageType === ApiType.PackageType.Solution ||
        a.packageType === ApiType.PackageType.Data ||
        a.packageType === ApiType.PackageType.Data2
    );
    let cIPackage = l03.packages?.filter((a: ApiType.Package) => a.packageType === ApiType.PackageType.CI);
    let armPackage = l03.additionalDeploymentParametersAvailable;
    if (dataverseSelection && dataverseSelection.length > 0) {
      let dataverseValidation = new DataversePackageValidator(PackageType.DATAVERSE_PACKAGE);
      validDataVerseParams = dataverseValidation.validation(data);
    }
    if (cIPackage && cIPackage.length > 0) {
      let ciValidation = new CIPackageValidator(PackageType.CI_PACKAGE);
      validCIParams = ciValidation.validation(data);
    }
    if (armPackage) {
      let armValidation = new ARMPackageValidator(PackageType.ARM_PACKAGE);
      validARMParams = armValidation.validation(azureDeployInstance);
    }
  });

  validARMParams = validARMParams === undefined ? { ok: true } : validARMParams;
  validCIParams = validCIParams === undefined ? { ok: true } : validCIParams;
  validDataVerseParams = validDataVerseParams === undefined ? { ok: true } : validDataVerseParams;

  isPackagesValidated = validARMParams.ok && validDataVerseParams.ok && validCIParams.ok;
  customProps[Constants.TelemetryPropertyNames.invalidDataVerseParamKey] =
    validDataVerseParams.ok !== true ? validDataVerseParams.message : undefined;
  customProps[Constants.TelemetryPropertyNames.invalidARMParamKey] =
    validARMParams.ok !== true ? validARMParams.message : undefined;
  customProps[Constants.TelemetryPropertyNames.invalidCIParamKey] =
    validCIParams.ok !== true ? validCIParams.message : undefined;
  telemetry.logEvents(telemetryConstants.events.DEPLOYMENT_PARAMS_INVALID, customProps);
  return isPackagesValidated;
};

export default DeployService;
