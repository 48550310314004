import * as React from 'react';
import { ActionButton, IconButton } from '@fluentui/react';
import { PreDeploymentConfigurations } from '../../preDeploymentConfigurations/preDeploymentConfigurations';
import LoadingScreen from '../../../../common/loading-screen';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import styles, { responsiveLicenseStatusBar, responsiveStatusBar, topMargin } from '../solutionsContainer.styles';
import * as ApiType from '../../../../solutionCenterApi/gen/index';
import DeployService from '../../../../services/deployService';
import InstallationStateContainer from '../../../../stateContainers/installationStateContainer';
import { UpdateDialog } from '../../update-dialog/update-dialog';
import { telemetry } from '../../../../services/telemetryService';
import { telemetryConstants } from '../../../../config';
import DependencyService from '../../../../services/dependencyService';
import { UpdateDetails } from '../update-details/update-details';
import * as Enum from '../../../../common/Enum';
import * as Constants from '../../../../common/Constants';
import { DeploymentFailedStatus } from '../../../../components/statusBars/deploymentFailedStatus';
import { InProgressStatus } from '../../../../components/statusBars/inProgressStatus';
import { LicenseMissingStatus } from '../../../../components/statusBars/licenseMissingStatus';
import { UpdateAvailableStatus } from '../../../../components/statusBars/updateAvailableStatus';
import LearnMoreDialog from '../../../products/product/learn-more-dialog/index';
import Utils, { getUniqDependencies } from '../../../../utils';
import EnvironmentService from '../../../../services/environmentsService';
import * as Util from '../../../../utils/helper';
import Visible from '../../../../components/visible/visible';
import { AZURE, PBILaunchName, TelemetryPropertyNames } from '../../../../common/Constants';
import * as Type from '../../../../common/Type';
import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { ResponsiveModeContext } from '../../../../contexts/ResponsiveModeContext';
import { createEndUserLaunchLink } from '../../../../utils/azureHelper';
import { validateApplicationPackages } from '../../../../utils/applicationPackagesValidationHelper';

export interface OptionalComponentProps {
  component: ApiType.OptionalComponent;
  deployStatus: string;
  l01RowKey: string;
  intl: IntlShape;
  getDeploymentL03s: () => void;
}

export const OptionalComponentContainer: FC<OptionalComponentProps> = (props: OptionalComponentProps): ReactElement => {
  const { deployStatus, intl, component, l01RowKey } = props;
  const [downArrowClicked, setDownArrowClicked] = useState<boolean>(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState<boolean>(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState<boolean>(false);
  const [updateConfirmed, setUpdateConfirmed] = useState<boolean>(false);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [licenseMissingClicked, setLicenseMissingClicked] = useState<boolean>(false);
  const [preDeploymentDependencies, setPreDeploymentDependencies] = useState<ApiType.L03Dependency[]>([]);
  const [disableUpdateButton, setDisableUpdateButton] = useState<boolean>(false);
  const [isFetchingDependencies, setIsFetchingDependencies] = useState<boolean>(true);
  const responsiveMode = useContext(ResponsiveModeContext);

  useEffect(() => {
    checkDependencies();
    validatePackages();
  }, []);

  const onDownButtonClick = (): void => {
    setDownArrowClicked(true);
  };

  const onUpButtonClick = (): void => {
    setDownArrowClicked(false);
  };

  const onUpdateButtonClick = (): void => {
    setUpdateButtonClicked(true);
    setIsUpdateDialogOpen(true);
  };

  const onDialogDismiss = (): void => {
    setUpdateButtonClicked(false);
    setIsUpdateDialogOpen(false);
    setUpdateConfirmed(false);
    setTermsChecked(false);
  };

  const onTermsChecked = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    setTermsChecked(!!checked);
    telemetry.logEvents(telemetryConstants.events.UPDATE_TERMS_ACCEPTED);
  };

  const onConfirmClick = async (): Promise<void> => {
    var customProps: Record<string, ApiType.DeploymentL03V2UpdatePayload | string> = {};
    if (props.component.deploymentL03V2RowKey) {
      try {
        const deploymentL03V2UpdatePayload: ApiType.DeploymentL03V2UpdatePayload = {
          deploymentL03V2RowKey: props.component.deploymentL03V2RowKey,
          l01RowKey: props.l01RowKey,
        };

        const envs = await EnvironmentService.getEnvironments(props.l01RowKey);
        const existingEnv: ApiType.Instance[] = envs.filter(
          (env: ApiType.Instance) => env.id === props.component.instanceId
        );
        if (existingEnv.length !== 0) {
          deploymentL03V2UpdatePayload.environmentId = existingEnv[0].environmentId;
        }

        customProps[TelemetryPropertyNames.deploymentPayload] = deploymentL03V2UpdatePayload;
        var result: ApiType.DeploymentResponse = await DeployService.updateL03(deploymentL03V2UpdatePayload);
        if (result.deploymentId) {
          InstallationStateContainer.setDeploymentId(result.deploymentId);
          customProps[TelemetryPropertyNames.deploymentIdKey] = result.deploymentId;

          setUpdateConfirmed(true);
          setDisableUpdateButton(true);

          props.getDeploymentL03s();
          telemetry.logEvents(telemetryConstants.events.UPDATE_CONFIRMED, customProps);
        }
      } catch (error) {
        telemetry.logException(error);
      }
    }
  };

  const returnUserLaunchName = (component: ApiType.OptionalComponent): string => {
    const armLaunchName = 'success.launchSolution.resourceDetails';
    const dataverseLaunchName = 'solutionContainer.launchButton';
    if (component?.additionalDeploymentParametersAvailable) return armLaunchName;
    else if (component?.instanceType === ApiType.PackageType.PBITemplateApp) return PBILaunchName;
    else return dataverseLaunchName;
  };

  const returnDecoratedEndUserLaunchLink = (): string | null => {
    var endUserLink: string | undefined = props.component.endUserLaunchLink;
    if (endUserLink) {
      var instanceApiUrl = props.component.instanceApiUrl;
      if (instanceApiUrl) {
        var instanceUrl: string = instanceApiUrl.replace('api.', '');
        const { geo, orgName } = Utils.splitInstanceUrl(instanceUrl);
        endUserLink = endUserLink.replace('{orgname}', orgName).replace('{geo}', geo);
      }
      if (props.component.additionalDeploymentParametersAvailable) {
        endUserLink = createEndUserLaunchLink(endUserLink);
      }
      return endUserLink;
    } else {
      return null;
    }
  };

  const renderLaunchButton = (endUserLaunchLink: string | null): JSX.Element | null => {
    if (endUserLaunchLink) {
      return (
        <ActionButton
          href={endUserLaunchLink}
          target="_blank"
          rel="noreferrer noopener"
          className={responsiveStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.Completed], responsiveMode)}
          iconProps={{ iconName: 'OpenInNewWindow' }}
        >
          <FormattedMessage id={returnUserLaunchName(props.component)} />
        </ActionButton>
      );
    } else {
      return null;
    }
  };

  const validatePackages = async (): Promise<void> => {
    if (props.component.isUpdateAvailable === true) {
      let isPackageValid = await validateApplicationPackages(props.l01RowKey, props.component);
      setDisableUpdateButton(disableUpdateButton || !isPackageValid);
    }
  };

  const checkDependencies = async (): Promise<void> => {
    var customProps: Record<string, string | boolean | ApiType.DependencyCheckResult | undefined> = {};
    try {
      let instanceId = props.component.instanceId;
      customProps[TelemetryPropertyNames.instanceIdKey] = props.component.instanceId;
      let instanceApiUrl: string | undefined = props.component.instanceApiUrl;
      customProps[TelemetryPropertyNames.instanceApiUrlKey] = props.component.instanceApiUrl;
      const preDependencies: ApiType.L03Dependency[] | undefined = props.component.l03Dependencies?.filter(
        (x: ApiType.L03Dependency) => x.preOrPostDeploy === 'Pre'
      );
      const uniquePreDependencies: ApiType.L03Dependency[] = getUniqDependencies(preDependencies);

      if (props.component.isUpdateAvailable === true && uniquePreDependencies.length !== 0) {
        if (props.l01RowKey) {
          const envs = await EnvironmentService.getEnvironments(props.l01RowKey);

          const existingEnv: ApiType.Instance[] = envs.filter((env: ApiType.Instance) => env.id === instanceId);
          if (existingEnv.length !== 0) {
            customProps[TelemetryPropertyNames.instanceNameKey] = existingEnv[0].friendlyName;
            const dependencies: ApiType.DependencyCheckResult = await DependencyService.getPreDeploymentDependencies(
              instanceApiUrl ?? existingEnv[0].apiUrl!,
              existingEnv[0].id,
              uniquePreDependencies
            );

            if (dependencies !== undefined) {
              customProps[TelemetryPropertyNames.dependenciesKey] = dependencies;

              if (dependencies.dependencyCheckFetchXMLResult) {
                const checkedDependencies: Type.DependencyChecked[] = Util.getCheckedDependencies(
                  uniquePreDependencies,
                  dependencies.dependencyCheckFetchXMLResult
                );

                const checkIsConfigured: Type.DependencyChecked | undefined = checkedDependencies.find(
                  (dependency: Type.DependencyChecked) => dependency.isConfigured === false
                );
                const checkIsDeployed: Type.DependencyChecked | undefined = checkedDependencies.find(
                  (dependency: Type.DependencyChecked) => dependency.isDeployed === false
                );

                const isLicensePresent = Utils.productHasLicense(props.component);
                customProps[TelemetryPropertyNames.isLicensePresent] = isLicensePresent;
                customProps[TelemetryPropertyNames.isDependencyConfigured] =
                  checkIsConfigured !== undefined ? false : true;
                customProps[TelemetryPropertyNames.isDependencyInstalled] =
                  checkIsDeployed !== undefined ? false : true;

                setPreDeploymentDependencies(checkedDependencies);
                setDisableUpdateButton(
                  checkIsDeployed !== undefined || checkIsConfigured !== undefined || !isLicensePresent ? true : false
                );
                setIsFetchingDependencies(false);
              } else {
                telemetry.logEvents(telemetryConstants.events.DEPENDENCY_CHECK_RESULT_UNDEFINED, customProps);

                setDisableUpdateButton(true);
                setIsFetchingDependencies(false);
              }
            } else {
              telemetry.logEvents(telemetryConstants.events.DEPENDENCY_CHECK_FAILED, customProps);

              setDisableUpdateButton(true);
              setIsFetchingDependencies(false);
            }
          } else {
            telemetry.logTrace(
              'Unable to get environment Id for dependency check',
              telemetryConstants.severity.SEVERITY_ERROR
            );

            setDisableUpdateButton(true);
            setIsFetchingDependencies(false);
          }
        }
      } else {
        setPreDeploymentDependencies(uniquePreDependencies);
        setIsFetchingDependencies(false);
      }
    } catch (error) {
      telemetry.logException(error);
    }
  };

  const renderLicenseMissingStatusBar = (isLicensePresent: boolean, left: string): JSX.Element | null => {
    if (!isLicensePresent) {
      return (
        <div onClick={() => setLicenseMissingClicked(true)}>
          <LicenseMissingStatus top={topMargin(responsiveMode)} left={left} />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderDeploymentStatus = (isLicensePresent: boolean, status: string, updateAvailable: boolean): JSX.Element => {
    var customProps: Record<string, string> = {};
    customProps[Constants.TelemetryPropertyNames.optionalComponentNameKey] = props.component.name;
    if (status === Enum.DeploymentStatus[Enum.DeploymentStatus.Success]) {
      let licenseStatus = Enum.DeploymentStatus.Completed;
      if (updateAvailable) {
        telemetry.logEvents(telemetryConstants.events.OPTIONAL_COMPONENT_UPDATE_AVAILABLE, customProps);
        licenseStatus = Enum.DeploymentStatus.UpdateAvailable;
      }

      return (
        <div className={styles.solutionStatusContainer}>
          {renderLicenseMissingStatusBar(
            isLicensePresent,
            responsiveLicenseStatusBar(Enum.DeploymentStatus[`${licenseStatus}`], responsiveMode)
          )}
          {updateAvailable && (
            <UpdateAvailableStatus
              top={topMargin(responsiveMode)}
              left={responsiveStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.UpdateAvailable], responsiveMode)}
            />
          )}
          {renderLaunchButton(returnDecoratedEndUserLaunchLink())}
        </div>
      );
    } else if (
      status === Enum.DeploymentStatus[Enum.DeploymentStatus.InProgress] ||
      status === Enum.DeploymentStatus[Enum.DeploymentStatus.NotStarted]
    ) {
      telemetry.logEvents(telemetryConstants.events.OPTIONAL_COMPONENT_IN_PROGRESS, customProps);
      return (
        <div className={styles.solutionStatusContainer}>
          {renderLicenseMissingStatusBar(
            isLicensePresent,
            responsiveLicenseStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.InProgress], responsiveMode)
          )}
          <InProgressStatus
            top={topMargin(responsiveMode)}
            left={responsiveStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.InProgress], responsiveMode)}
          />
        </div>
      );
    } else if (status === Enum.DeploymentStatus[Enum.DeploymentStatus.Failure]) {
      telemetry.logEvents(telemetryConstants.events.OPTIONAL_COMPONENT_DEPLOYMENT_FAILED, customProps);
      return (
        <div className={styles.solutionStatusContainer}>
          {renderLicenseMissingStatusBar(
            isLicensePresent,
            responsiveLicenseStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.Failure], responsiveMode)
          )}
          <DeploymentFailedStatus
            top={topMargin(responsiveMode)}
            left={responsiveStatusBar(Enum.DeploymentStatus[Enum.DeploymentStatus.Failure], responsiveMode)}
          />
        </div>
      );
    }
  };

  const preDependencies: ApiType.L03Dependency[] | undefined = component.l03Dependencies?.filter(
    (x: ApiType.L03Dependency) => x.preOrPostDeploy === 'Pre' && x.type !== AZURE
  );

  const isLicensePresent = Utils.productHasLicense(component);
  return (
    <div>
      <div className={styles.solutionBox}>
        <div id="optionalComponentContainerTitle" className={styles.solutionTitleContainer}>
          <span
            className={
              component?.installedVersion?.name !== undefined
                ? styles.solutionTitle
                : styles.solutionTitleWithoutVersion
            }
          >
            {component.name}
          </span>
          {renderDeploymentStatus(isLicensePresent, deployStatus, component.isUpdateAvailable!)}
          {downArrowClicked ? (
            <IconButton
              ariaLabel={intl.formatMessage({
                id: 'deploymentDetails.button.upArrow',
              })}
              onClick={onUpButtonClick}
              className={styles.arrowStyle}
              iconProps={{ iconName: 'ChevronUp' }}
            ></IconButton>
          ) : preDependencies?.length !== 0 || component.isUpdateAvailable === true ? (
            <IconButton
              ariaLabel={intl.formatMessage({
                id: 'deploymentDetails.button.downArrow',
              })}
              onClick={onDownButtonClick}
              className={styles.arrowStyle}
              iconProps={{ iconName: 'ChevronDown' }}
            ></IconButton>
          ) : null}
        </div>
        {downArrowClicked &&
        deployStatus === Enum.DeploymentStatus[Enum.DeploymentStatus.Success] &&
        component.isUpdateAvailable === true ? (
          <UpdateDetails
            intl={intl}
            solution={component}
            isFetchingDependencies={isFetchingDependencies}
            disableUpdateButton={disableUpdateButton}
            totalPreDeploymentDependencies={preDeploymentDependencies.length}
            onUpdateButtonClick={onUpdateButtonClick}
            preDeploymentDependencies={preDeploymentDependencies}
          />
        ) : downArrowClicked ? (
          <div id="preDependencies" className={styles.preDeploymentConfigurationContainer}>
            <Visible when={!isFetchingDependencies} fallback={<LoadingScreen isVisible={true} label={''} />}>
              {preDeploymentDependencies.length !== 0 ? (
                <PreDeploymentConfigurations
                  intl={intl}
                  solution={component}
                  preDeploymentDependencies={preDeploymentDependencies}
                />
              ) : null}
            </Visible>
          </div>
        ) : null}
        {updateButtonClicked ? (
          <UpdateDialog
            onDismiss={onDialogDismiss}
            onConfirmClick={onConfirmClick}
            isOpen={isUpdateDialogOpen}
            confirmed={updateConfirmed}
            onTermsChecked={onTermsChecked}
            termsChecked={termsChecked}
            l01RowKey={l01RowKey}
            intl={intl}
            solution={component}
          />
        ) : null}
        {licenseMissingClicked && !isLicensePresent ? (
          <LearnMoreDialog
            missingLicenses={Utils.getProductMissingLicenses(component)}
            isOpen={licenseMissingClicked}
            close={() => setLicenseMissingClicked(false)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default injectIntl(OptionalComponentContainer);
