import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toggleVisibility } from '../../utils/toggleVisibility';
import { Sidebar } from '@business-app/fabric/lib/components/Sidebar';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { telemetry } from '../../services/telemetryService';
import config, { telemetryConstants } from '../../config';
import SolutionsStateContainer from '../../stateContainers/solutionsStateContainer';
import SolutionService from '../../services/solutionsService';
import { useIntl } from 'react-intl';
import * as ApiType from '../../solutionCenterApi/gen';
import { IsDesktopMode, IsZoomMode } from '../../common/responsiveMode';
import style from './navMenu.styles';
import { CustomSupportPanel } from '../customSupportPanel/customSupportPanel';
import { useBoolean } from '@fluentui/react-hooks';
import { ISidebarItemProps } from '@business-app/fabric';
import { sortProductsByName, shortenSolutionName } from '../../utils/helper';
import RuntimeConfigStateContainer from '../../stateContainers/runtimeConfigStateContainer';
import * as Constant from '../../common/Constants';
import * as Util from '../../utils/helper';
import { AssociatedIndustryVertical } from '../../../src/common/Type';
import * as Enum from '../../common/Enum';
import { TelemetryPropertyNames } from '../../common/Constants';

const styles = {
  root: {
    borderRight: '0px solid rgb(208, 208, 208);',
    '@media(max-width: 960px)': {
      position: 'relative',
      pointerEvent: 'none',
    },
  },
};

interface navMenuProps {}

const NavMenu = (props: navMenuProps) => {
  const history = useHistory();
  const location = useLocation();
  const intlShape = useIntl();
  const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();

  const logLinkClick = (name: string, url: string, eventName?: string) => {
    let navigationOptionSelected: Record<string, string> = {};
    navigationOptionSelected[telemetryConstants.navigation.NAVIGATION_TELEMETRY_KEY] = name;
    navigationOptionSelected[TelemetryPropertyNames.urlKey] = url;
    navigationOptionSelected[TelemetryPropertyNames.homePageClickEvent] = eventName;
    telemetry.logEvents(telemetryConstants.navigation.NAVIGATION_TELEMETRY_NAME, navigationOptionSelected);
  };
  const linkClickHandler = (
    event: React.FormEvent,
    { name, key, url, clickEventName }: { name: string; key: string; url: string; clickEventName: string }
  ) => {
    event.preventDefault();
    logLinkClick(name, url, clickEventName);
    SolutionsStateContainer.setSelectedSolution(key);
    if (name === intlShape.formatMessage({ id: 'navMenu.deployments' })) {
      SolutionsStateContainer.setGridListView(true);
      SolutionsStateContainer.setFilteredSearchKeyword('');
      SolutionsStateContainer.setFilteredSelectedSort(Enum.SortOptionsL03s.SortNewest);
      SolutionsStateContainer.setModifiedByFilterKeys([]);
      SolutionsStateContainer.setDeploymentStatus([]);
    }
    history.push({ pathname: url, state: { solutionRowKey: key } });
  };
  const verticalLinkClickHandler = (
    solution: { name: string; key: string },
    industryVertical: { name: string; key: string },
    url: string
  ) => {
    logLinkClick(solution.name, url);
    SolutionsStateContainer.setSelectedIndustryVerticalKey(industryVertical.key);
    SolutionsStateContainer.setSelectedSolution(solution.key);
    history.push({ pathname: url });
  };
  const externalLinkClickHandler = () => {
    logLinkClick(telemetryConstants.supportPanel.NAVIGATION_OPTION, '');
    openGlobalSupportPanel();
  };
  const [isGlobalSupportPanelOpen, { setTrue: openGlobalSupportPanel, setFalse: closeGlobalSupportPanel }] =
    useBoolean(false);
  const navLinksData: ISidebarItemProps[] = [
    {
      name: intlShape.formatMessage({ id: 'navMenu.home' }),
      key: 'key1',
      url: config.routes.home,
      clickEventName: telemetryConstants.navigation.HOME_LINK_CLICKED,
      onClick: linkClickHandler,
      iconProps: {
        iconName: 'Home',
      },
      active: window.location.pathname === config.routes.home,
    },
    {
      name: intlShape.formatMessage({ id: 'home.yourDeployments' }),
      key: 'key2',
      url: config.routes.yourDeployments,
      clickEventName: telemetryConstants.navigation.DEPLOYMENTMANAGER_LINK_CLICKED,
      onClick: linkClickHandler,
      iconProps: {
        id: 'deploymentsIconId',
        iconName: 'CloudDownload',
      },
      active: window.location.pathname === config.routes.yourDeployments,
    },
    {
      name: intlShape.formatMessage({ id: 'navMenu.support' }),
      key: 'key3',
      url: config.supportLink,
      clickEventName: telemetryConstants.navigation.PRODUCT_SUPPORT_LINK_CLICKED,
      onClick: externalLinkClickHandler,
      iconProps: {
        iconName: 'DataManagementSettings',
      },
    },
  ];
  if (runtimeConfig.featureFlag.enableLearningHubPage) {
    navLinksData.push({
      name: intlShape.formatMessage({ id: 'Learning Hub' }),
      key: 'key5',
      url: config.routes.learningHub,
      clickEventName: telemetryConstants.navigation.LEARNING_HUB_LINK_CLICKED,
      onClick: linkClickHandler,
      iconProps: {
        iconName: 'ReadingMode',
      },
      active: window.location.pathname === config.routes.learningHub,
    });
  }

  const [navLinks, setNavLinks] = useState(navLinksData);
  const _sideBar: React.LegacyRef<Sidebar> = React.createRef();

  useEffect(() => {
    setNavigationLinks();
  }, [location]);

  const setNavigationLinks = async () => {
    const pathname = window.location.pathname;
    navLinksData.forEach((product) => (product.active = product.url === pathname));
    let solutions: ApiType.L01[] = SolutionsStateContainer.getAllSolutions();
    if (!solutions) {
      solutions = await SolutionService.getAllSolutions();
    }
    if (solutions) {
      const selectedProduct = SolutionsStateContainer.getSelectedSolution();
      const selectedIndustyVertical = SolutionsStateContainer.getSelectedIndustryVerticalKey();
      let products = SolutionsStateContainer.getIsVisibleSolutions();
      if (!products) {
        products = await toggleVisibility(solutions);
      }
      if (!runtimeConfig.featureFlag.enableIndustryVerticals) {
        const isAnotherActive = navLinksData.some((navLink: ISidebarItemProps) => navLink.active);
        const navLink = {
          key: 'Solutions',
          name: intlShape.formatMessage({ id: 'navMenu.solutions' }),
          iconProps: {
            id: 'solutionsIconId',
            iconName: 'OEM',
          },
          items: products.map((product: ApiType.L01) => ({
            key: product.rowKey,
            name: shortenSolutionName(product.solutionName),
            url: config.routes.home + product.solutionName,
            clickEventName: shortenSolutionName(product.solutionName),
            onClick: linkClickHandler,
            active:
              pathname.substring(1) === product.solutionName ||
              (selectedProduct === product.rowKey && !isAnotherActive),
          })),
        };
        setNavLinks([navLinksData[0], navLinksData[1], navLink, navLinksData[2], navLinksData[3]]);
      } else {
        const isAnotherSolutionActive = navLinksData.some((navLink) => navLink.active);
        const isAnotherIndustryVerticalActive = navLinksData.some(
          (navLink: ISidebarItemProps) =>
            navLink.active || navLink.items?.some((item: ISidebarItemProps) => item.active)
        );
        sortProductsByName(products);
        const industryVerticalList: ISidebarItemProps[] = [...navLinksData];

        const industryCloudsTitle = {
          name: intlShape.formatMessage({ id: 'navMenu.industryClouds' }),
          key: 'key4',
          url: '',
          iconProps: {
            iconName: '',
          },
          styles: {
            root: {
              left: '16px',
              span: {
                fontWeight: 600,
              },
              'pointer-events': 'none',
            },
          },
        };
        industryVerticalList.push(industryCloudsTitle);

        products.forEach((product: ApiType.L01) => {
          const associatedIndustryVerticals: AssociatedIndustryVertical[] =
            Util.parseAssociatedIndustryVerticals(product);
          if (associatedIndustryVerticals?.length > 0) {
            sortProductsByName(associatedIndustryVerticals);
            const isProductActive =
              decodeURI(pathname).substring(1) === product.solutionName ||
              (selectedProduct === product.rowKey && !isAnotherSolutionActive);
            const industryVerticalsList: ISidebarItemProps[] = [];
            associatedIndustryVerticals.forEach((vertical: AssociatedIndustryVertical) => {
              const isIndustyVerticalActive =
                decodeURI(pathname).substring(1) === product.solutionName &&
                selectedIndustyVertical === vertical.rowKey &&
                selectedProduct === product.rowKey;
              industryVerticalsList.push({
                key: vertical.rowKey,
                name: vertical.name,
                url: config.routes.home + product.solutionName,
                onClick: () =>
                  verticalLinkClickHandler(
                    { name: shortenSolutionName(product.solutionName), key: product.rowKey },
                    { name: vertical.name, key: vertical.rowKey },
                    config.routes.home + product.solutionName
                  ),
                active: isIndustyVerticalActive,
              });
            });

            const allSolutionsName = intlShape.formatMessage({ id: 'navMenu.allSolutions' });
            const isIndustyVerticalAllSolutionsActive =
              decodeURI(pathname).substring(1) === product.solutionName &&
              selectedIndustyVertical === Constant.All &&
              selectedProduct === product.rowKey;
            industryVerticalsList.push({
              key: allSolutionsName,
              name: allSolutionsName,
              url: config.routes.home + product.solutionName,
              onClick: () =>
                verticalLinkClickHandler(
                  { name: shortenSolutionName(product.solutionName), key: product.rowKey },
                  { name: allSolutionsName, key: Constant.All },
                  config.routes.home + product.solutionName
                ),
              active: isIndustyVerticalAllSolutionsActive,
            });

            const currentVerticalsLink: ISidebarItemProps = {
              key: product.rowKey,
              name: shortenSolutionName(product.solutionName),
              iconProps: {
                imageProps: {
                  src: product.industryCloudIcon,
                },
              },
              url: config.routes.home + product.solutionName,
              active: isProductActive,
              items: industryVerticalsList,
            };
            industryVerticalList.push(currentVerticalsLink);
          } else {
            const currentVerticalsLink: ISidebarItemProps = {
              key: product.rowKey,
              url: config.routes.home + product.solutionName,
              name: shortenSolutionName(product.solutionName),
              clickEventName: shortenSolutionName(product.solutionName),
              onClick: linkClickHandler,
              iconProps: {
                imageProps: {
                  src: product.industryCloudIcon,
                },
              },
              active:
                pathname.substring(1) === product.solutionName ||
                (selectedProduct === product.rowKey && !isAnotherSolutionActive && !isAnotherIndustryVerticalActive),
            };
            industryVerticalList.push(currentVerticalsLink);
          }
        });
        setNavLinks(industryVerticalList);
      }
    } else {
      setNavLinks(navLinksData);
    }
  };

  return (
    <section className={style.navMenuSection} style={{ minHeight: 'calc(100vh - 48px)' }}>
      {IsDesktopMode() && !location.pathname.startsWith(config.routes.setupGuidesBasePath) && (
        <Sidebar
          collapseButtonAriaLabel={intlShape.formatMessage({
            id: 'navMenu.accessibility.CollapseExpandButton',
          })}
          styles={styles}
          collapsible={true}
          defaultIsCollapsed={false}
          id={'sidebar-basic'}
          theme={getTheme()}
          items={navLinks}
        />
      )}

      {IsZoomMode() && !location.pathname.startsWith(config.routes.setupGuidesBasePath) && (
        <Sidebar
          collapseButtonAriaLabel={intlShape.formatMessage({
            id: 'navMenu.accessibility.CollapseExpandButton',
          })}
          ref={_sideBar}
          styles={style}
          collapsible={true}
          defaultIsCollapsed={true}
          id={'sidebar-expanded'}
          theme={getTheme()}
          items={navLinks}
        />
      )}

      <CustomSupportPanel
        isOpen={isGlobalSupportPanelOpen}
        onDismiss={closeGlobalSupportPanel}
        selectedL01RowKey={SolutionsStateContainer.getSelectedSolution()}
      />
    </section>
  );
};

export default NavMenu;
