import { mergeStyleSets, IDetailsColumnStyles } from '@fluentui/react';

const styles = mergeStyleSets({
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconHeaderIcon: {
    paddingLeft: 10,
    fontSize: '16px',
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  textStyle: {
    color: '#323130',
    whiteSpace: 'pre-wrap',
  },
  buttonStyle: {
    border: 'none',
    background: 'none',
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  detailsListStyle: {
    paddingBlockEnd: 16,
    paddingInlineStart: 16,
    paddingInlineEnd: 16,
  },
});

export const tableHeaderStyles: Partial<IDetailsColumnStyles> = {
  root: {
    fontWeight: 600,
    verticalAlign: 'bottom',
    color: '#323130',
  },
};

export default styles;
