import { PackageType } from './Enum';

export function getEnvironmentTypeString(packageType: PackageType): string {
  switch (packageType) {
    case PackageType.ARM_PACKAGE:
      return 'ARM';
    case PackageType.CI_PACKAGE:
      return 'CI';
    case PackageType.DATAVERSE_PACKAGE:
      return 'TPS';
    case PackageType.PBI_PACKAGE:
      return 'PBITemplateApp';
    default:
      return '';
  }
}
